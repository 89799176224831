import React, { useContext, useEffect, useState } from 'react';
import { PageContext } from '../../context/context';
import { constants } from '../../utils/constants';
import { searchSalesforce } from '../../utils/queries';
import { Autocomplete, Button, Chip, CircularProgress, Paper, TextField } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { primaryTheme } from '../../utils/theme';
import AddIcon from '@mui/icons-material/Add';
import CreateRecord from '../RelatedLists/CreateRecord';
import SearchIcon from '@mui/icons-material/Search';

import styles from './Inputs.module.css';

/**
 * @summary Input field to search database for records similar to Salesforce's Lookup field. Only support single select.
 * @function
 * @namespace components/Inputs/InputLookup
 * @description This component has the same implementation as InputAutocomplete.js except it only supports single value.
 * @example <InputLookup config id label sectionId onChange defaultValue attributes></InputLookup>
*/
const InputLookup = (props) => {
  // console.log('from InputLookup', props);
  const { config, id, label, sectionId, onChange } = props || {};
  const { defaultValue, attributes } = config || {};
  const { color = "#007bc2", idField = "Id", noOptionsText = "No options", placeholder, required, variant, searchLimit } = attributes || {};

  const { handleOnChangeWithDetail, salesforceUser, valueStorage } = useContext(PageContext);
  const [currentSearchKey, setCurrentSearchKey] = useState('');
  const [createRecordConfig, setCreateRecordConfig] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [options, setOptions] = useState([]);
  let searchTimeout;

  const [internalValue, setInternalValue] = useState(null);
  useEffect(() => {
    if (valueStorage?.[sectionId]?.[id]) {
      const newValue = valueStorage[sectionId][id].value;
      if (newValue !== internalValue) {
        setInternalValue(newValue);
      };
    };
  }, [valueStorage, sectionId, id]);

  useEffect(() => {
    if (defaultValue && salesforceUser) {
      getRecords(defaultValue);
    };
  }, [defaultValue, salesforceUser]);


  /**
   * @summary Handler for the button's onclick is impleneted inside this function.
   * @function
   * @memberof components/Inputs/InputLookup
   * @description Return a react object for the "+ New Record" button if attributes.newRecordLayout is not null.
   * @param {boolean} hasNoResult
  */
  const createQuickAddButton = (hasNoResult) => {
    if (attributes?.newRecordLayout) {
      const newButton = (
        <div key={`new-btn-${id}`} className={styles.newRecordButton} data-no-result={!!hasNoResult}>
          <Button variant="text" size="small"
            startIcon={<AddIcon fontSize="large" sx={{ minWidth: "2rem" }} />}
            onClick={() => {
              document.querySelector(`input[id="${id}_input"]`)?.blur(); // unfocus current input field
              const newLayout = JSON.parse(JSON.stringify(attributes.newRecordLayout));
              if (!!currentSearchKey) {
                newLayout.inputFields.some(inputConfig => {
                  if (inputConfig.targetField === 'Name' || inputConfig.targetField === 'FirstName' || inputConfig.targetField === 'LastName') {
                    inputConfig.defaultValue = currentSearchKey;
                    return true;
                  }
                });
              }
              setCreateRecordConfig(newLayout);
            }}>
            {attributes.newRecordLayout.title}
          </Button>
        </div>
      );
      return newButton;
    } else {
      return null;
    };
  };

  /**
   * @summary Return a react object for the PaperComponent of Autocomplete.
   * @function
   * @memberof components/Inputs/InputLookup
   * @description This function injects the QuickAddButton to the bottom of the component.
   * No result and has results UI were handled differently here.
   * @param {object} props
  */
  const customPaperCmp = (props) => {
    const newButton = createQuickAddButton(props?.children?.[1]);
    const childrenList = props?.children?.props?.children;
    if (newButton && typeof childrenList !== "string" && childrenList?.length > 0) {
      if (childrenList[childrenList.length - 1]?.key !== `new-btn-${id}`) {
        if (typeof childrenList[0] === "string") {
          childrenList.push(newButton);
        } else {
          childrenList.push(<li key={`btn-new-${id}`}>{newButton}</li>);
        }
      }
    };
    const { key, ...otherProps } = props;
    return (<Paper key={key ? `custom-${key}-${id}` : `custom-auto-${id}`} {...otherProps} />);
  };

  /**
   * @summary Return a react object for the renderOption of Autocomplete.
   * @function
   * @memberof components/Inputs/InputLookup
   * @description Additional data related to the search results were added and displayed in the dropdown.
   * @param {object} props
   * @param {object} option
  */
  const customRenderOption = (props, option) => {
    const { key, ...otherProps } = props;
    return (
      <li {...otherProps} key={`${key}-${props.id}-`}>
        <div className="grid_vertical size_1-1 p-vertical_x-small">
          <div className="text-ellipsis">
            <span title={option.label} className={styles.searchResultLabel} style={{ color: color }}>
              {option.label}
            </span>
            {option.sublabel &&
              <>
                <span className="p-horizontal_x-small">•</span>
                <i className={styles.searchResultSublabel}>{option.sublabel}</i>
              </>
            }
          </div>
          <div className={styles.searchResultDescription}>
            {option.description}
          </div>
        </div>
      </li>
    );
  };

  /**
   * @summary This function acts as a value setter, not search result handler
   * @function
   * @memberof components/Inputs/InputLookup
   * @description Construct query parameters and call searchSalesforce from utils/queries.
   * Used for setting default values or auto assigning values without actual search.
   * @param {string} recordId
  */
  const getRecords = async (recordId) => {
    if (!recordId) return;

    setIsSearching(true);
    const params = { ...attributes };
    params.userInfo = salesforceUser;
    params.filter = idField + "='" + recordId + "'";
    params.enableSOQL = true;

    const newOptions = await searchSalesforce(params);
    handleOnChange({}, newOptions[0]);
    setIsSearching(false);
  };

  /**
   * @summary Populate query results into dropdown
   * @function
   * @memberof components/Inputs/InputLookup
   * @description Construct query parameters and call searchSalesforce from utils/queries.
   * @param {string} searchKey
  */
  const getSearchResults = async (searchKey) => {
    setIsSearching(true);
    setCurrentSearchKey(searchKey);
    const params = { ...attributes, searchKey };
    params.limit = typeof searchLimit === "number" ? searchLimit : 5;
    params.userInfo = salesforceUser;

    const newOptions = await searchSalesforce(params);
    setOptions(newOptions);
    setIsSearching(false);
  };

  /**
   * @summary Update internalValue and ensure uniqueness of selected values.
   * @function
   * @memberof components/Inputs/InputLookup
   * @description Triggered when selected values for this component is changed.
   * Construct detail object to send to parent for further handling and storage of changes.
   * @param {event} e
   * @param {string} newValue
  */
  const handleOnChange = (e, newValue) => {
    // console.log('here', newValue)
    setInternalValue(newValue);
    const detail = {
      inputId: id,
      sectionId: sectionId,
      targetField: config?.targetField,
      type: config?.type,
      value: newValue
    };
    if (typeof onChange === 'function') {
      onChange(detail);
    } else {
      handleOnChangeWithDetail(detail);
    };
  };

  /**
   * @summary Handler for the onchange event in renderInput of Autocomplete.
   * @function
   * @memberof components/Inputs/InputLookup
   * @description Calls getSearchResults after 500ms of change and if the input vaue has at leat 3 characters.
   * @param {event} event
  */
  const handleSearchInputOnChange = (event) => {
    const searchKey = event.target.value;
    clearTimeout(searchTimeout);
    if (searchKey && searchKey.trim().length > 2) {
      searchTimeout = setTimeout(() => {
        getSearchResults(searchKey.trim());
      }, 500);
    } else {
      setIsSearching(false);
    };
  };


  return (
    <div className="input-autocomplete">
      <ThemeProvider theme={primaryTheme(color || themeColors.primary)}>
        <Autocomplete
          // ChipProps={{ color: "primary" }}
          filterOptions={() => options}
          fullWidth
          id={id + '_input'}
          isOptionEqualToValue={(o, v) => o.value === v.value}
          loading={isSearching}
          loadingText="Searching..."
          noOptionsText={[noOptionsText]}
          onChange={handleOnChange}
          options={options}
          PaperComponent={customPaperCmp}
          popupIcon={<SearchIcon />}
          renderInput={(params) =>
            <TextField {...params}
              required={required || false}
              label={label}
              variant={variant}
              placeholder={placeholder}
              onChange={handleSearchInputOnChange}
              InputProps={{
                ...params.InputProps,
                maxLength: 80,
                endAdornment: (
                  <>
                    {isSearching ? <CircularProgress color="primary" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          }
          renderOption={customRenderOption}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <Chip {...getTagProps({ index })}
                color={"primary"}
                key={`${option.label}-${index}`}
                label={option.label}
                size={"small"}
                title={option.label}
                variant={"outlined"}
              />
            ));
          }}
          size={"small"}
          value={internalValue}
        />
      </ThemeProvider>

      {createRecordConfig &&
        <div className={`${styles.lookupNewRecord} ${constants.EMAIL_CLIENT === "outlook" && styles.isOutlookLookup}`}>
          <div>
            <CreateRecord {...createRecordConfig}
              onCancel={() => {
                setCreateRecordConfig(null);
              }}
              onSuccessSave={(detail) => {
                getRecords(detail.recordId);
                setCreateRecordConfig(null);
              }} />
          </div>
        </div>
      }
    </div>
  );
};

export default InputLookup;
