import { useContext } from 'react';
import { PageContext } from '../context/context';
import { DmlContext } from '../context/dmlContext';
import { constants } from '../utils/constants';
import AttachmentsPanel2 from '../components/Attachments/AttachmentsPanel2';
import ActionButtonsWrapper from '../components/ActionButtons/ActionButtonsWrapper';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import SectionCmp from "../components/Sections/SectionCmp";

import styles from './templates.module.css';


/**
 * @summary Log Interaction Template
 * @interface LogInteractionTemplate
 * @description Screen for logging an email or meeting
 */
const LogInteractionTemplate = () => {
  const { attachmentsConfig, interactionId, logScreenBottom, logType, saveResultToast, sections, setScreenType, themeColors } = useContext(PageContext);
  const { beginSaveInteractionProcess } = useContext(DmlContext);

  const showAttachments = () => {
    return (
      !!attachmentsConfig &&
      ((attachmentsConfig.forEmail && logType === 'email')
        || (attachmentsConfig.forMeeting && logType === 'meeting'))
    );
  };

  return (
    <>
      <div className={styles.logScreenTitle} style={{ backgroundColor: themeColors.primary }}>
        {logType === 'email' ? <MailOutlineIcon sx={{ padding: "2px 8px" }} /> : <MeetingRoomIcon sx={{ padding: "2px 8px" }} />}
        Log {logType === 'email' ? 'Email' : 'Meeting'}
      </div>

      {sections &&
        sections.map((item, key) => (
          <SectionCmp key={`${item.id}-${key}-section`} config={item} />
        ))
      }

      {showAttachments() && (logType !== 'meeting' || constants.EMAIL_CLIENT === 'outlook') &&
        <div style={{ paddingTop: "0.25rem" }}>
          <AttachmentsPanel2 hideSaveButton={true}
            enableCase={false}
            enableEmail={logType === 'email' && attachmentsConfig.forEmail}
            enableMeeting={logType === 'meeting' && attachmentsConfig.forMeeting}
            selectByDefaultEmail={!interactionId && attachmentsConfig.selectByDefaultEmail}
            selectByDefaultMeeting={!interactionId && attachmentsConfig.selectByDefaultMeeting} />
        </div>
      }

      {saveResultToast && saveResultToast}

      <div className={styles.buttonPadding} ref={logScreenBottom}></div>
      <ActionButtonsWrapper
        isUpdate={!!interactionId}
        onSave={beginSaveInteractionProcess}
        onClose={() => setScreenType("intro")} />
    </>
  );
};

export default LogInteractionTemplate;
