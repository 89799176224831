/**
 * @fileOverview creates header component
 * @module components/Header
 * @require @mui
*/
import { useContext } from "react";
import { PageContext } from '../../context/context';
import { AppBar, Box, Toolbar } from "@mui/material";
import investorFlowLogo from "../../assets/images/logo-investorflow.png";
import CustomAvatar from "../User/CustomAvatar";
import { constants } from "../../utils/constants";


/**
 * @summary header component
 * @description creates a custom header component with avatar
 * @function
 * @param {boolean} props.showUserMenu - bool to show user menu
 * @param {number} props.userMenuCmpIdid - unique component id
 * @returns {component}
*/
const Header = (props) => {
  // console.log('from header', props);
  const { showUserMenu, userMenuCmpId } = props || {};
  const { googleAuthUser, isConnected, msAuthUser, salesforceUser, setUserMenuAnchorEl, setShowUserMenu, themeColors } = useContext(PageContext);
  const user = msAuthUser ? msAuthUser?.email?.substr(0, 1) : googleAuthUser?.email?.substr(0, 1);
  const isOutlook = constants.EMAIL_CLIENT === "outlook" ? true : false;

  return (
    <div style={{ display: isOutlook ? "none" : "block" }}>
      <Box sx={{ flexGrow: 1}}>
        <AppBar position="fixed" sx={{ backgroundColor: "#00213F" }}>
          <Toolbar variant="dense" sx={{ minHeight: "48px", paddingRight: isOutlook ? "40px!important" : "16px!important", paddingLeft: "16px!important" }}>
            <div className="grid_spread size_1-1" >
              <Box component="img"
                alt="InvestorFlow Logo"
                src={investorFlowLogo}
                sx={{ height: "24px", marginRight: "8px" }}
              />
              { isConnected &&
                <CustomAvatar
                  id={userMenuCmpId}
                  onClick={(event) => {
                    setUserMenuAnchorEl(event.currentTarget);
                    setShowUserMenu(!showUserMenu);
                  }}
                  salesforceUser={salesforceUser?.displayName}
                  themeColor={themeColors.tertiary}
                  user={user}
                />
              }
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};

export default Header;
