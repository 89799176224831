import React, { useContext, useEffect, useState } from 'react';
import { PageContext } from '../../context/context';
import { ThemeProvider } from '@mui/material/styles';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { primaryTheme } from '../../utils/theme';


const InputEmail = (props) => {
  // console.log('from InputEmail', props);
  const { config, id, label, sectionId, onChange } = props || {};
  const { color, multiple, required, variant } = config?.attributes || {};
  const { handleOnChangeWithDetail, valueStorage, themeColors } = useContext(PageContext);
  const [options, setOptions] = useState([]);

  let parserTimeout;
  /* START Internal Value */
  const [internalValue, setInternalValue] = useState(() => {
    let newValue = multiple ? [] : '';
    if (config?.defaultValue) {
      if (typeof config.defaultValue === 'string') {
        if (multiple) {
          newValue = config.defaultValue.split(";");
        } else {
          newValue = config.defaultValue;
        };
      } else if (config.defaultValue.length > 0) {
        newValue = config.defaultValue;
      };
    };
    return newValue;
  });

  useEffect(() => {
    if (valueStorage?.[sectionId]?.[id]) {
      const newValue = valueStorage[sectionId][id].value;
      if (newValue) {
        if (typeof newValue === 'string') {
          setInternalValue(multiple ? newValue.split(";") : newValue);
        } else if (newValue.length > 0 && newValue[0]) {
          setInternalValue(multiple ? newValue : newValue[0]);
        } else {
          // console.log('down', newValue)
          // newValue[0] === undefined can happen if value storage is set wrong
        };
      };
    };
  }, [valueStorage, sectionId, id]);
  /* END Internal Value */

  const parseEmailInput = (event) => {
    const inputValue = event.target.value;
    clearTimeout(parserTimeout);
    if (inputValue && inputValue.length >= 5) {
      parserTimeout = setTimeout(() => {
        let isValid = false;
        const parsedTexts = inputValue.trim().replaceAll(' ', '').split('@');
        if (parsedTexts.length === 2) {
          const dotIndex = parsedTexts[1].indexOf('.');
          if (dotIndex > 0 && dotIndex < parsedTexts[1].length - 1) {
            isValid = true;
          };
        };
        if (isValid) {
          const email = parsedTexts.join('@');
          if (multiple) {
            const valueSet = new Set(internalValue);
            valueSet.add(email);
            setOptions([email]);
            setInternalValue([...valueSet.values()]);
            handleOnChange(event, [...valueSet.values()]);
          } else {
            setInternalValue(email);
            handleOnChange(event, email);
          }
        } else {
          setOptions([]);
        };
      }, 800);
    } else {
      setOptions([]);
    };
  };

  const handleOnChange = (event, newValues) => {
    //console.log('onchange', JSON.parse(JSON.stringify(newValues)), internalValue);
    let tempValue = newValues;
    if (!multiple) {
      if (typeof newValues === "string") {
        tempValue = newValues;
      } else if (newValues?.length > 0) {
        tempValue = newValues[newValues.length - 1];
      } else {
        tempValue = '';
      };
    };
    setInternalValue(tempValue);

    const detail = {
      inputId: id,
      sectionId: sectionId,
      targetField: config?.targetField,
      type: config?.type,
      value: typeof tempValue === "string" ? [tempValue] : tempValue
    };
    if (typeof onChange === 'function') {
      onChange(detail);
    } else {
      handleOnChangeWithDetail(detail);
    };
  };


  return (
    <ThemeProvider theme={primaryTheme(color || themeColors.primary)}>
      <Autocomplete
        // ChipProps={{ color: "primary", variant: "outlined"}}
        disableClearable
        filterSelectedOptions
        fullWidth
        multiple={multiple}
        // TODO: should we update this text?
        noOptionsText={"Enter Valid Email"}
        onChange={handleOnChange}
        options={options}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <Chip {...getTagProps({ index })}
              color={"primary"}
              key={`${option}-${index}`}
              label={option}
              size={"small"}
              title={option}
              variant={"outlined"}
            />
          ));
        }}
        renderInput={(params) => <TextField {...params} label={label} onChange={parseEmailInput} required={required} variant={variant} />}
        size="small"
        value={internalValue}
      />
    </ThemeProvider>
  );
};

export default InputEmail;
