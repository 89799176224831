/* eslint-disable no-undef */
/**
 * @fileOverview functions used to parse outlook Item
 * @namespace outlookItemFunctions
*/
import { getExtendedIds, getEventOutlookUid, restructureHTML } from './helperFunctions';


const buildOfficeExchangeItem = async (item) => {
  await Office.context.mailbox.item.saveAsync(function(result) {
    item.itemId = result.value;
  });
  await Office.context.mailbox.item.end.getAsync(function(result) {
    item.ewsEnd = result.value;
  });
  await Office.context.mailbox.item.location.getAsync(function(result) {
    item.ewsLocation = result.value;
  });
  await Office.context.mailbox.item.optionalAttendees.getAsync(function(result) {
    item.ewsOptionalAttendees = result.value;
  });
  await Office.context.mailbox.item.organizer.getAsync(function(result) {
    item.ewsOrganizer = result.value;
  });
  await Office.context.mailbox.item.requiredAttendees.getAsync(function(result) {
    item.ewsRequiredAttendees = result.value;
  });
  await Office.context.mailbox.item.start.getAsync(function(result) {
    item.ewsStart = result.value;
  });
  await Office.context.mailbox.item.subject.getAsync(function(result) {
    item.ewsSubject = result.value;
  });

  return item;
};

const getBCC = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.bcc?.getAsync) {
      item.bcc.getAsync(function (result) {
        try {
          resolve(_item.bcc = result.value);
        } catch (e) {
          console.log('getBCC Error: ', e);
          resolve(_item.bcc = '');
        };
      });
    } else {
      resolve(_item.bcc = item.bcc);
    };
  }));
};

const getBody = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.body?.getAsync) {
      item.body.getAsync("html", function (result) {
        try {
          const body = restructureHTML(result.value);
          resolve(item.itemType !== "message" ? _item.calendarbody = body : _item.emailbody = body);
        } catch (e) {
          console.log('getBody ERROR: ', e);
          resolve(item.itemType !== "message" ? _item.calendarbody = '' : _item.textemailbody = '');
        };
      });
    } else {
      resolve(item.itemType !== "message" ? _item.calendarbody = '' : _item.textemailbody = '');
    };
  }));
};

const getBodyText = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.body?.getAsync) {
      item?.body?.getAsync("text", function (result) {
        try {
          resolve(item.itemType !== "message" ? _item.textcalendarbody = result.value : _item.textemailbody = result.value);
        } catch (e) {
          console.log('getBody Text ERROR: ', e);
          resolve(item.itemType !== "message" ? _item.textcalendarbody = '' : _item.textemailbody = '');
        };
      });
    } else {
      resolve(item.itemType !== "message" ? _item.textcalendarbody = '' : _item.textemailbody = '');
    };
  }));
};

const getCC = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.cc?.getAsync) {
      item.cc.getAsync(function (result) {
        try {
          resolve(_item.cc = result.value);
        } catch (e) {
          console.log('getCC Error: ', e);
          resolve(_item.cc = '');
        };
      });
    } else {
      resolve(_item.cc = item.cc);
    };
  }));
};

const getEnd = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.end?.getAsync) {
      item.end.getAsync(function (result) {
        try {
          resolve(_item.end = result.value.toISOString());
        } catch (e) {
          console.log('getEnd ERROR: ', e);
          resolve(_item.end = item.dateTimeCreated ? item.dateTimeCreated.toISOString() : '');
        };
      });
    } else {
      resolve(_item.end = item.end);
    };
  }));
};

const getFrom = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.from?.getAsync) {
      item.from.getAsync(function (result) {
        try {
          resolve(_item.from = result.value);
        } catch (e) {
          console.log('getFrom Error: ', e);
          resolve(_item.from = '');
        };
      });
    } else {
      resolve(_item.from = item.from);
    };
  }));
};

const getItemRestId = (itemId) => {
  if (Office?.context?.mailbox?.diagnostics?.hostName === 'OutlookIOS') {
    return itemId;
  } else {
    // Convert to an item ID for API v2.0.
    return Office?.context?.mailbox?.convertToRestId ? Office?.context?.mailbox?.convertToRestId(itemId, Office?.MailboxEnums?.RestVersion?.v2_0) : itemId;
  };
};

const getLocation = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.location?.getAsync) {
      item.location.getAsync(function (result) {
        try {
          resolve(_item.location = result.value);
        } catch (e) {
          console.log('getLocation Error: ', e);
          resolve(_item.location = '');
        };
      });
    } else {
      resolve(_item.location = item.location);
    };
  }));
};

const getOptionalAttendees = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.optionalAttendees?.getAsync) {
      item.optionalAttendees.getAsync(function (result) {
        try {
          resolve(_item.optionalAttendees = result.value);
        } catch (e) {
          console.log('getOptionalAttendees ERROR: ', e);
          resolve(_item.optionalAttendees = []);
        };
      });
    } else {
      resolve(_item.optionalAttendees = item.optionalAttendees);
    };
  }));
};

const getOrganizer = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.organizer?.getAsync) {
      item.organizer.getAsync(function (result) {
        try {
          resolve(_item.organizer = result.value);
        } catch (e) {
          console.log('getOrganizer Error: ', e);
          resolve(_item.organizer = {});
        };
      });
    } else {
      resolve(_item.organizer = item.organizer);
    };
  }));
};

const getRequiredAttendees = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.requiredAttendees?.getAsync) {
      item.requiredAttendees.getAsync(function (result) {
        try {
          resolve(_item.requiredAttendees = result.value);
        } catch (e) {
          console.log('getRequiredAttendees ERROR: ', e);
          resolve(_item.requiredAttendees = []);
        };
      });
    } else {
      resolve(_item.requiredAttendees = item.requiredAttendees);
    };
  }));
};

const getStart = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.start?.getAsync) {
      item.start.getAsync(function (result) {
        try {
          resolve(_item.start = result.value.toISOString());
        } catch (e) {
          console.log('getStart ERROR: ', e);
          resolve(_item.start = item.dateTimeCreated ? item.dateTimeCreated.toISOString() : '');
        };
      });
    } else {
      resolve(_item.start = item.start);
    };
  }));
};

const getSubject = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.subject?.getAsync) {
      item.subject.getAsync(function (result) {
        try {
          resolve(_item.subject = result.value);
        } catch (e) {
          console.log('getSubject Error: ', e);
          resolve(_item.subject = '');
        };
      });
    } else {
      resolve(_item.subject = item.subject);
    };
  }));
};

const getTo = async (item, _item) => {
  return (new Promise(function (resolve) {
    if (item?.to?.getAsync) {
      item.to.getAsync(function (result) {
        try {
          resolve(_item.to = result.value);
        } catch (e) {
          console.log('getTo Error: ', e);
          resolve(_item.to = '');
        };
      });
    } else {
      resolve(_item.to = item.to);
    };
  }));
};

const saveItemAsynch = async () => {
  return new Office.Promise(function (resolve, reject) {
    try {
      Office.context?.mailbox?.item?.saveAsync(function callback (result) {
        resolve(result);
      });
    } catch (error) {
      console.log('Error with outlook save item async', error);
      reject(error);
    };
  });
};


const loadPromiseMailASynch = async (item, _item) => {
  const promises = [];
  promises.push(getBCC(item, _item));
  promises.push(getBody(item, _item));
  promises.push(getBodyText(item, _item));
  promises.push(getCC(item, _item));
  promises.push(getFrom(item, _item));
  promises.push(getTo(item, _item));
  promises.push(getSubject(item, _item));
  return promises;
};

const loadPromiseMeetingsASynch = async (item, _item) => {
  const promises = [];
  promises.push(getBody(item, _item));
  promises.push(getBodyText(item, _item));
  promises.push(getEnd(item, _item));
  promises.push(getLocation(item, _item));
  promises.push(getOptionalAttendees(item, _item));
  promises.push(getOrganizer(item, _item));
  promises.push(getRequiredAttendees(item, _item));
  promises.push(getStart(item, _item));
  promises.push(getSubject(item, _item));
  return promises;
};


export { buildOfficeExchangeItem, getItemRestId, loadPromiseMailASynch, loadPromiseMeetingsASynch, saveItemAsynch };
