/**
 * @fileOverview functions to create theme using material ui throughout the project
 * @namespace theme
 * @requires createTheme
*/
import { createTheme } from '@mui/material/styles';

/**
 * @summary adds themeing to the app
 * @function
 * @memberof theme
 * @description uses MUI create theme to add a primary color change to the app with both light and dark versions
 * @param {string} main - main primary color
 * @param {string} dark - darkened primary color
 * @param {string} light - lightened primary color
*/
const expansiveTheme = (main, dark, light) => {
	return createTheme({
		palette: {
			primary: {
				main: main,
				dark: dark,
				light: light
			}
		}
	});
};

/**
 * @summary adds themeing to the app
 * @function
 * @memberof theme
 * @description uses MUI create theme to add a primary color change to the app, has a fallback of #007BC2 for default
 * @param {string} color - main primary color
*/
const primaryTheme = (color = '#007BC2') => {
	return createTheme({
		palette: {
			primary: {
				main: color,
			}
		}
	});
};

/**
 * @summary adds themeing to the app
 * @function
 * @memberof theme
 * @description uses MUI create theme to add up to three colors in the app
 * @param {string} primary - main primary color - buttons
 * @param {string} secondary - main secondaryy color - links
 * @param {string} tertiary - main tertiary color - borders and icons
*/
const triTheme = (primary, secondary, tertiary) => {
	return createTheme({
    palette: {
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
      tertiary: {
        main: tertiary,
      }
    }
	});
};

export { expansiveTheme, primaryTheme, triTheme };
