/**
 * @fileOverview uses componentsMap to render the correct component with props to the template
 * @namespace renders
*/
import { componentsMap } from '../maps/componentsMap';

/**
 * @summary ...
 * @function
 * @memberof renders
 * @description ...
 * @param {object} config - ...
 * @param {string} key - ...
 * @param {object} parent - ...
 * @param {function} onChange - ...
*/
const renderComponents = (config, key, parent, onChange) => {
  // console.log('from render nested componentes', key, config);
  if (!config) return null;
  const Component = componentsMap[config?.type];

  return (
    <Component key={`${key}-${config?.id}`}
      id={config?.id}
      config={config}
      label={config?.label}
      sectionId={parent}
      onChange={onChange}
    />
  );
};

export default renderComponents;
