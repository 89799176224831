import { useContext, useEffect, useState } from 'react';
import { constants } from '../../utils/constants';
import { LoadingContext } from '../../context/loadingContext';
import { PageContext } from '../../context/context';
import { parseNameString } from '../../utils/helperFunctions';
import { searchSOQL } from '../../utils/queries';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography, Button } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { primaryTheme } from '../../utils/theme';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CircularProgress from '@mui/material/CircularProgress';
import CreateRecord from "./CreateRecord";
import ColoredSvg from '../CustomSvg/ColoredSvg';
import dayjs from 'dayjs';
import EditModeSection from "./EditModeSection";
import RelatedItem from './RelatedItem';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import styles from './RelatedLists.module.css';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * @summary Retrieve and create data related the Email and Meeting
 * @function
 * @namespace RelatedList
 * @description There are generally 3 different ways the Related List generate its items: Interaction Links related to the Email/Meeting,
 * filter attribute, and entity recognition matcher. Contact object list gets an additional query to retrieve data based on detected
 * email addresses.
 * Filter merge fields: {!parentId} {!activityId} {!caseId}
 * @example ...
 */
const RelatedList = (props) => {
  // console.log('from related list', props?.objectName, props)
  const {
    caseId, color, defaultClosed, descriptionFields, enableEdit, enableNew, enableQuery, entityRecognitionObject, externalId, filter, iconName, inputId, interactionId,
    label, labelField, labelFieldType, newRecordLayout, objectName, onSelectItem, orderBy, parentIdField, recordTypeAPINames, requireParentId,
    salesforceUser, suggestedItems, themeColors, titleField, titleFieldType
  } = props || {};
  const [childrenResults, setChildrenResults] = useState(null);
  const [createRecordConfig, setCreateRecordConfig] = useState(null);
  const [detectedResults, setDetectedResults] = useState(null);
  const [existingResults, setExistingResults] = useState(null);
  const [filterResults, setFilterResults] = useState(null);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [items, setItems] = useState(null);
  const [moreSize, setMoreSize] = useState(5);
  const [selectedItemIds, setSelectedItemIds] = useState(new Set());
  const [suggestedResults, setSuggestedResults] = useState(null);

  const {
    detectedEntities,
    detectEntityIsRunning,
    existingLinks,
    orgInfo,
    parentIdMap, setParentIdMap,
  } = useContext(PageContext);

  const [isLoadingRL, setIsLoadingRL] = useState(false);
  const [isLoadingCL, setIsLoadingCL] = useState(false);
  const [isLoadingER, setIsLoadingER] = useState(false);
  const [isLoadingDE, setIsLoadingDE] = useState(false);
  const { isLoadingGlobal } = useContext(LoadingContext);

  const topPosition = constants.EMAIL_CLIENT === "outlook" && !isEditClicked ? styles.topZero : "";

  // useEffect(() => {
  //   if (constants.EMAIL_CLIENT === 'outlook' && Office?.context?.mailbox?.item?.itemType === "appointment") {
  //   } else {
  //     setChildrenResults(null);
  //     setDetectedResults(null);
  //     setExistingResults(null);
  //     setFilterResults(null);
  //     setSuggestedResults(null);
  //     setItems(null);
  //   };
  // }, [externalId]);

  // TODO: change these useEffects the various item results into one and use a promise.all
  useEffect(() => {
    if ((interactionId || caseId) && objectName && salesforceUser) {
      if (enableQuery && (
        (filter?.indexOf("{!activityId}") > 0 && interactionId) ||
        (filter?.indexOf("{!caseId}") > 0 && caseId)
      )) {
        getChildrenList();
      } else {
        setChildrenResults(null);
      };
    };
  }, [caseId, filter, interactionId, enableQuery, objectName, salesforceUser]);

  useEffect(() => {
    if (externalId && objectName && salesforceUser) {
      if (enableQuery && Object.values(parentIdMap).length > 0) {
        getRelatedList();
      } else {
        setFilterResults(null);
      };
    };
  }, [objectName, salesforceUser, parentIdMap]);

  useEffect(() => {
    if (externalId && existingLinks?.[inputId]) {
      const idList = [];
      existingLinks[inputId].forEach(linkItem => {
        idList.push(linkItem.childId);
      });
      getExistingRecords(idList, "INTERACTION_LINK");
    } else {
      setExistingResults(null);
    };
  }, [existingLinks, inputId]);

  useEffect(() => {
    if (externalId && suggestedItems && suggestedItems.length > 0) {
      const existingIds = new Set(selectedItemIds);
      const newItems = [];
      suggestedItems.forEach(record => {
        const item = {
          title: getFieldValue(record, [{ fieldName: titleField, fieldType: titleFieldType }]),
          label: getFieldValue(record, [{ fieldName: labelField, fieldType: labelFieldType }]),
          description: getFieldValue(record, descriptionFields, true),
          record: record,
          checked: !!record?.Id && !interactionId
        };
        if (interactionId) {
          existingIds.delete(record.Id);
        }
        newItems.push(item);
      });
      if (existingIds.size != selectedItemIds.size) {
        setSelectedItemIds(existingIds);
      }
      setSuggestedResults(newItems);
    } else {
      setSuggestedResults(null);
    };
  }, [suggestedItems]);

  useEffect(() => {
    if (!!interactionId && suggestedResults?.length > 0) {
      const existingIds = new Set(selectedItemIds);
      const oldItems = JSON.parse(JSON.stringify(suggestedResults));
      oldItems.forEach(item => {
        delete item.checked;
        if (item.record?.Id) {
          existingIds.delete(item.record.Id);
        }
      });
      if (existingIds.size != selectedItemIds.size) {
        setSelectedItemIds(existingIds);
      }
      setSuggestedResults(oldItems);
    }
  }, [interactionId]);

  useEffect(() => {
    generateItemList();
  }, [childrenResults, detectedResults, existingResults, externalId, filterResults, suggestedResults]);

  useEffect(() => {
    if (!!entityRecognitionObject && salesforceUser?.entityRecognition) {
      if (detectedEntities?.[objectName]?.length > 0) {
        getDetectedEntityResults(detectedEntities[objectName]);
      } else {
        setDetectedResults(null);
      };
    };
  }, [detectedEntities]);

  const appendCurrencyCodeField = (fieldNameSet, fieldName, fieldType) => {
    if (fieldType === 'currency' && orgInfo.isMultiCurrency) {
      const fnList = fieldName.split(".");
      fnList[fnList.length - 1] = 'CurrencyIsoCode';
      fieldNameSet.add(fnList.join("."));
      //fieldNameSet.add(`convertCurrency(${fieldName})+cvt_${fieldName.replace(/\./g, '')}`);
    }
  };

  const createNewRecord = async (detail) => {
    // console.log(detail, newRecordLayout, items);
    let defaultItem = items[detail.itemIndex].record;
    const layout = JSON.parse(JSON.stringify(newRecordLayout));
    layout.itemIndex = detail.itemIndex;
    if (defaultItem.Id) {
      // This will open in EDIT mode
      layout.recordId = defaultItem.Id;
      defaultItem = await getRecordForEdit(layout);
      layout.title = getFieldValue(defaultItem, [{ fieldName: titleField, fieldType: titleFieldType }]);
      layout.title = layout.title ? layout.title : `Edit ${objectName}`;
    }
    layout.inputFields.forEach(inputConfig => {
      if (layout.recordId) {
        if (defaultItem[inputConfig.targetField]) {
          inputConfig.defaultValue = defaultItem[inputConfig.targetField];
        }
        if (inputConfig.type === 'picklist') {
          // Set RecordTypeId for picklist field
          inputConfig.recordTypeId = defaultItem.RecordTypeId;
        }
      } else if (defaultItem[inputConfig.sourceField]) {
        inputConfig.defaultValue = defaultItem[inputConfig.sourceField];
      }
      // Initialize currency code for existing records
      if (inputConfig.type === 'currency' && defaultItem.CurrencyIsoCode) {
        inputConfig.currencyCode = defaultItem.CurrencyIsoCode;
      }
    });
    setCreateRecordConfig(layout);
  };

  const fireSelectItemEvent = (newItems) => {
    if (typeof onSelectItem === "function") {
      const selectedList = [];
      (newItems || items).forEach(item => {
        if (item.checked) {
          selectedList.push(item);
        }
      });
      onSelectItem({
        inputId: inputId,
        items: selectedList
      });
    };
  };

  const generateItemList = async () => {
    const uniqueMap = JSON.parse(generateUniqueMap());
    const checkedList = [];
    const unCheckedList = [];
    const dneList = [];
    Object.values(uniqueMap).forEach(item => {
      if (item.checked) {
        checkedList.push(item);
      } else {
        if (!item.record?.Id) {
          dneList.push(item);
        } else {
          unCheckedList.push(item);
        }
      }
    })
    //console.log(objectName);
    // console.log('lists', objectName, [...checkedList, ...unCheckedList, ...dneList]);
    let mergedArray = [...checkedList, ...unCheckedList, ...dneList];
    if (orderBy) {
      // Skip sorting and let the Salesforce sort takes over
    } else {
      mergedArray = sortByTitleWithRecordId(mergedArray);
    };

    const existingIds = new Set(selectedItemIds);
    mergedArray.forEach(item => {
      if (item.record?.Id) {
        if (item.checked) {
          existingIds.add(item.record.Id);
        } else if (existingIds.has(item.record.Id)) {
          item.checked = true;
        };
      };
    });

    if (objectName === 'Contact') {
      if (mergedArray.length > 0) {
        mergedArray = removeDuplicateContacts(mergedArray, 'title');
      };
    };

    setSelectedItemIds(existingIds);
    setItems(mergedArray);
    fireSelectItemEvent([...checkedList]);
  };

  const getChildrenList = async () => {
    setIsLoadingCL(true);
    const searchFilter = filter.replaceAll("{!activityId}", "'" + interactionId + "'").replaceAll("{!caseId}", "'" + caseId + "'");
    const newItems = await getSearchResultsAsItems(searchFilter);
    if (newItems) {
      setChildrenResults(newItems);
    } else {
      setChildrenResults(null);
    }
    setIsLoadingCL(false);
  };

  const getExistingRecords = async (recordIds, processType) => {
    setIsLoadingER(true);
    const searchFilter = "Id+IN+('" + recordIds.join("','") + "')";
    const newItems = await getSearchResultsAsItems(searchFilter);
    if (newItems) {
      newItems.forEach(item => {
        if (processType === "INTERACTION_LINK") {
          item.checked = true;
          item.isLinked = true;
        }
      });
      setExistingResults(newItems);
    } else {
      setExistingResults(null);
    }
    setIsLoadingER(false);
  };

  const getFieldNames = () => {
    const fieldNameSet = new Set();
    fieldNameSet.add("Id");
    if (titleField) {
      fieldNameSet.add(titleField);
      appendCurrencyCodeField(fieldNameSet, titleField, titleFieldType);
    }
    if (labelField) {
      fieldNameSet.add(labelField);
      appendCurrencyCodeField(fieldNameSet, labelField, labelFieldType);
    }
    if (descriptionFields && descriptionFields.length > 0) {
      descriptionFields.forEach(dfr => {
        if (dfr.fieldName) {
          fieldNameSet.add(dfr.fieldName);
          appendCurrencyCodeField(fieldNameSet, dfr.fieldName, dfr.fieldType);
        }
      });
    }
    if (parentIdField) {
      fieldNameSet.add(parentIdField);
    }
    if (enableEdit && newRecordLayout) {
      newRecordLayout.inputFields?.forEach(inputConfig => {
        fieldNameSet.add(inputConfig.targetField);
      });
    }
    if (orderBy) {
      orderBy.split(",").forEach(orderField => {
        fieldNameSet.add(orderField.trim().split(" ")[0]);
      });
    }
    return [...fieldNameSet.values()];
  };

  const getFieldValue = (record, dfrList, resultIsList) => {
    const valueList = [];
    dfrList?.forEach(dfr => {
      let fnList = dfr.fieldName.split('.');
      let tempObj = record;
      fnList.forEach(fn => {
        if (tempObj) {
          tempObj = tempObj[fn];
        }
      });
      if (tempObj) {
        if ("datetime" === dfr.fieldType) {
          valueList.push(dayjs(tempObj).local().format("L LT"));
        } else if ("date" === dfr.fieldType) {
          valueList.push(dayjs(tempObj).format("L"));
        } else if ("time" === dfr.fieldType) {
          const timeParts = tempObj.split(/\D+/);
          let hourInt = 0, minuteInt = 0;
          try {
            hourInt = parseInt(timeParts[0]);
            minuteInt = parseInt(timeParts[1]);
          } catch (e) { }
          const highNoon = hourInt >= 12 ? "PM" : "AM";
          if (hourInt > 12) hourInt = hourInt % 12;
          if (hourInt === 0) hourInt = 12;
          const hh = hourInt < 10 ? "0" + hourInt : "" + hourInt;
          const mm = minuteInt < 10 ? "0" + minuteInt : "" + minuteInt;
          valueList.push(`${hh}:${mm} ${highNoon}`);
        } else if (typeof tempObj === "string" && tempObj.indexOf("</") > 0 && tempObj.indexOf("<") >= 0 && tempObj.indexOf(">") > 0) {
          valueList.push(tempObj);
        } else if (dfr.fieldType === "currency") {
          let moneyCode = 'USD';
          if (orgInfo.defaultCurrencyCode) moneyCode = orgInfo.defaultCurrencyCode;
          if (record.CurrencyIsoCode) moneyCode = record.CurrencyIsoCode;
          const formatter = new Intl.NumberFormat('en-US', {
            style: dfr.fieldType,
            currency: moneyCode
          });
          valueList.push(formatter.format(tempObj));
        } else if (dfr.fieldType === "percent") {
          const formatter = new Intl.NumberFormat('en-US', {
            style: dfr.fieldType
          });
          valueList.push(formatter.format(tempObj / 100.0));
        } else {
          valueList.push(tempObj);
        }
      }
    });
    return resultIsList ? valueList : valueList.join(". ");
  };

  const getRecordForEdit = async (layout) => {
    const fieldNames = new Set(["Id", "RecordTypeId"]);
    layout.inputFields.forEach(inputConfig => {
      fieldNames.add(inputConfig.targetField);
      if (inputConfig.type === 'currency' && orgInfo.isMultiCurrency) {
        fieldNames.add("CurrencyIsoCode");
      }
    });
    const options = { headers: { 'Content-Type': 'application/json' } };
    const params = {
      userInfo: salesforceUser,
      objectName: objectName,
      labelField: titleField,
      filter: `Id='${layout.recordId}'`,
    };
    const records = await searchSOQL(params, options, [...fieldNames.values()]);
    return records[0];
  };

  const getRelatedList = async () => {
    if (filter?.indexOf("{!parentId}") > 0) {
      setIsLoadingRL(true);
      const parentIdList = [...(new Set([...Object.values(parentIdMap)])).values()];
      const searchFilter = filter.replaceAll("{!parentId}", "('" + parentIdList.join("','") + "')");
      //console.log(searchFilter);
      const newItems = await getSearchResultsAsItems(searchFilter);
      if (newItems) {
        setFilterResults(newItems);
      } else {
        setFilterResults(null);
      }
      setIsLoadingRL(false);
    }
  };

  const getSearchResultsAsItems = async (searchFilter) => {
    const options = { headers: { 'Content-Type': 'application/json' } };

    const params = {
      userInfo: salesforceUser,
      objectName: objectName,
      labelField: titleField,
      filter: searchFilter,
      orderBy: orderBy
    };

    const records = await searchSOQL(params, options, getFieldNames());
    // console.log('records', records)
    if (records && records.length > 0) {
      const newItems = [];
      records.forEach(record => {
        newItems.push({
          title: getFieldValue(record, [{ fieldName: titleField, fieldType: titleFieldType }]),
          label: getFieldValue(record, [{ fieldName: labelField, fieldType: labelFieldType }]),
          description: getFieldValue(record, descriptionFields, true),
          record: record
        });
      });
      return newItems;
    }
    return [];
  };

  const generateUniqueMap = () => {
    const uniqueMap = {};
    const resultList = [];
    if (childrenResults?.length > 0) resultList.push(...childrenResults);
    if (existingResults?.length > 0) resultList.push(...existingResults);
    if (filterResults?.length > 0) resultList.push(...filterResults);
    if (suggestedResults?.length > 0) resultList.push(...suggestedResults);
    if (detectedResults?.length > 0) resultList.push(...detectedResults);

    resultList.forEach(item => {
      let uniqueKey;
      if (item.record?.Id) {
        uniqueKey = item.record.Id;
      } else {
        uniqueKey = `${item.title}${item.label}${item.description}`;
      };
      // This is the attempt to decouple objects to avoid stale state
      const newItem = uniqueMap[uniqueKey] ? JSON.parse(JSON.stringify(uniqueMap[uniqueKey])) : JSON.parse(JSON.stringify(item));
      if (item.checked) {
        newItem.checked = true;
      };
      uniqueMap[uniqueKey] = newItem;
    });
    //console.log(JSON.stringify(uniqueMap));
    return JSON.stringify(uniqueMap);
  }

  const handleCancel = () => {
    setCreateRecordConfig(null);
    setIsEditClicked(false);
  };

  const handleCreateRecordSuccess = (detail) => {
    // detail => {recordId, objectName, itemIndex}
    console.log("handleCreateRecordSuccess", detail, createRecordConfig);
    const itemIndex = createRecordConfig.itemIndex;
    if (items[itemIndex].isDetectedEntity) {
      linkSearchResultToEntity(detail.recordId, itemIndex);
    } else {
      linkNewRecordToItemList(detail.recordId, itemIndex);
    };
    setCreateRecordConfig(null);
  };

  const handleLinkNewRecord = (linkResult, itemIndex) => {
    if (items[itemIndex].isDetectedEntity) {
      linkSearchResultToEntity(linkResult.recordId, itemIndex);
    };
  };

  const handleSuccessUpdate = (detail) => {
    getChildrenList();
    setIsEditing(false);
  };

  const linkNewRecordToItemList = async (recordId, itemIndex) => {
    const searchFilter = `Id='${recordId}'`;
    const results = await getSearchResultsAsItems(searchFilter);
    if (results?.length > 0) {
      const newItems = [...items];
      results[0].checked = true;
      newItems[itemIndex] = results[0];
      setItems(newItems);
      fireSelectItemEvent(newItems);
    };
  };

  const linkSearchResultToEntity = (recordId, itemIndex) => {
    const existingIds = new Set(selectedItemIds);
    existingIds.add(recordId);
    setSelectedItemIds(existingIds);

    const entityList = [...detectedEntities[objectName]];
    entityList.some(entity => {
      if (entity.name === items[itemIndex].title) {
        entity.id = recordId;
        return true;
      }
    });
    updateDetectedEntityStorage(JSON.parse(JSON.stringify(entityList)));
    entityList.some(entity => {
      if (entity.name === items[itemIndex].title) {
        entity.newRecord = true;
        return true;
      }
    });
    getDetectedEntityResults(entityList);
  };

  // a bit obscure but removing duplicate values by provided key (title) the second item seems to always be from to/from/cc/bcc and the first from ER hence the reverse
  const removeDuplicateContacts = (list, key) => {
    // const filteredContacts = [...new Map(list.map(item => [item[key], item])).values()];
    const filteredContacts = [...new Map(list.slice().reverse().map(item => [item[key], item])).values()].reverse();
    return filteredContacts;
  };

  const selectItem = (index) => {
    // This is looking at the old state.
    const existingIds = new Set(selectedItemIds);
    if (items[index].checked) {
      // Expected new state to be false
      existingIds.delete(items[index].record.Id);
    } else {
      // Expected new state to be true
      existingIds.add(items[index].record.Id);
    }

    const newParentIdMap = { ...parentIdMap };
    const newItems = [...items];
    newItems[index].checked = !newItems[index].checked;
    if (newItems[index].record && !!parentIdField) {
      delete newParentIdMap[newItems[index].record.Id];
      if (newItems[index].checked) {
        const newParentId = newItems[index].record[parentIdField];
        if (newParentId) {
          newParentIdMap[newItems[index].record.Id] = newParentId;
        }
      }
    }
    setItems(newItems);
    fireSelectItemEvent(newItems);
    if (!!parentIdField) {
      // This check is needed in order ot prevent un-neccessary chain refreshes
      setParentIdMap(newParentIdMap);
    }
    setSelectedItemIds(existingIds);
  };

  /** SORT */
  const sortByOrderFields = (data, orderByList) => {
    return data.slice().sort((a, b) => {
      const hasRecordIdA = a.record && a.record.Id;
      const hasRecordIdB = b.record && b.record.Id;

      let result = 0;
      orderByList.some(orderByPair => {
        const fn = orderByPair[0]; //field name
        const direction = orderByPair[1] === 'ASC' ? 1 : -1;
        if (hasRecordIdA && hasRecordIdB) {
          // Both items have record.Id, maintain the alphabetical order of titles
          const valueA = a.record[fn] ? (a.record[fn] + "").toLowerCase() : "";
          const valueB = b.record[fn] ? (b.record[fn] + "").toLowerCase() : "";
          const localResult = valueA.localeCompare(valueB);
          if (localResult !== 0) {
            result = localResult * direction;
            return true;
          }
        } else if (hasRecordIdA) {
          // Only item A has record.Id, it should appear at the top
          result = 0 - direction;
          return true;
        } else if (hasRecordIdB) {
          // Only item B has record.Id, it should appear at the top
          result = direction;
          return true;
        } else {
          // Neither item has record.Id, maintain alphabetical order of titles
          const titleA = a.title ? a.title.toLowerCase() : "";
          const titleB = b.title ? b.title.toLowerCase() : "";
          const localResult = titleA.localeCompare(titleB);
          if (localResult !== 0) {
            result = localResult * direction;
            return true;
          }
        }
      });
      return result;
    });
  };

  const sortByTitleWithRecordId = (data) => {
    return data.slice().sort((a, b) => {
      const hasRecordIdA = a.record && a.record.Id;
      const hasRecordIdB = b.record && b.record.Id;

      if (hasRecordIdA && hasRecordIdB) {
        // Both items have record.Id, maintain the alphabetical order of titles
        const titleA = a.title ? a.title.toLowerCase() : "";
        const titleB = b.title ? b.title.toLowerCase() : "";
        return titleA.localeCompare(titleB);
      } else if (hasRecordIdA) {
        // Only item A has record.Id, it should appear at the top
        return -1;
      } else if (hasRecordIdB) {
        // Only item B has record.Id, it should appear at the top
        return 1;
      } else {
        // Neither item has record.Id, maintain alphabetical order of titles
        const titleA = a.title ? a.title.toLowerCase() : "";
        const titleB = b.title ? b.title.toLowerCase() : "";
        return titleA.localeCompare(titleB);
      }
    });
  };
  /** END SORT */
  /** ENTITY RECOGNITION */
  const getDetectedEntityResults = async (entityList) => {
    setIsLoadingDE(true);

    const entityIdSet = new Set();
    const newRecordIdSet = new Set();
    entityList.forEach(entity => {
      if (!!entity.id) {
        entityIdSet.add(entity.id);
      }
      if (!!entity.newRecord) {
        newRecordIdSet.add(entity.id);
      }
    });

    let searchFilter = "Id+IN+('" + [...entityIdSet.values()].join("','") + "')";
    if (recordTypeAPINames?.length > 0) {
      searchFilter += " AND RecordType.DeveloperName IN ('" + recordTypeAPINames.join("','") + "')";
    };

    const newItems = await getSearchResultsAsItems(searchFilter);
    if (newItems) {
      newItems.forEach(item => {
        item.checked = newRecordIdSet.has(item.record.Id);
        item.isDetectedEntity = true;
      });
    };

    entityList.forEach(entity => {
      if (!entity.id) {
        const record = {};
        if (objectName.toLowerCase() === 'contact') {
          // console.log('process entity name', entity?.name);
          const firstName = parseNameString(entity?.name)?.firstName;
          const lastName = parseNameString(entity?.name)?.lastName;
          record.FirstName = firstName;
          if (lastName && lastName !== '') {
            record.LastName = lastName;
          };
        } else {
          record.Name = entity.name;
        }
        newItems.push({
          title: entity.name,
          record: record,
          checked: false,
          isDetectedEntity: true
        });
      }
    });

    if (newItems?.length > 0) {
      setDetectedResults(newItems);
    } else {
      setDetectedResults(null);
    }
    setIsLoadingDE(false);
  };

  const updateDetectedEntityStorage = (entityList) => {
    const storageJSON = window.sessionStorage.getItem(constants.ENTITY_RECOGNITION_STORAGE);
    let entityMap;
    let storage = [];
    JSON.parse(storageJSON).forEach(entityObj => {
      if (entityObj.externalId === externalId) {
        entityMap = entityObj;
      } else {
        storage.push(entityObj);
      }
    });
    entityMap[objectName] = entityList;
    storage.push(entityMap);
    // console.log("update storage", storage);
    window.sessionStorage.setItem(constants.ENTITY_RECOGNITION_STORAGE, JSON.stringify(storage));
  };
  /** END ENTITY RECOGNITION */

  return (
    <section className={styles.relatedList}>
      <Accordion defaultExpanded={!defaultClosed} disableGutters
        sx={{
          border: `1px solid ${themeColors.tertiary || "rgba(0, 0, 0, 0.2)"}`,
          borderRadius: "0!important",
          boxShadow: "none!important",
        }}>
        <AccordionSummary
          expandIcon={<ArrowDropUpIcon sx={{ color: themeColors.primary }} />}
          sx={{ padding: "0 0.5rem" }}>
          <div style={{ display: "flex" }}>
            {iconName &&
              <div style={{ marginRight: "0.5rem" }}>
                <ColoredSvg iconName={iconName} color={themeColors.tertiary} />
              </div>
            }
            <Typography
              sx={{
                margin: "auto",
                fontWeight: "bold",
                fontSize: "0.875rem",
                color: "#37474f",
                lineHeight: "1rem",
              }}>
              {label}
            </Typography>
            {(isLoadingRL || isLoadingCL || isLoadingER || isLoadingDE || isLoadingGlobal || (detectEntityIsRunning && !!entityRecognitionObject)) &&
              <CircularProgress size={20} sx={{ marginLeft: "1rem", color: themeColors.primary }} />
            }
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "0.5rem 0 1rem 0",
            borderTop: "1px solid rgba(0, 0, 0, 0.2)"
          }}>
          <ThemeProvider theme={primaryTheme(color || themeColors.primary)}>
            <div style={{ fontSize: "0.725rem" }}>
              <Stack spacing={1}>
                {items && items.length > 0
                  ?
                  items.map((item, index) =>
                    (index < moreSize) &&
                    <div key={`${index}-related`}>
                      <RelatedItem {...item} itemIndex={index}
                        isEditable={!!item.record?.Id}
                        enableCreateRecord={!!newRecordLayout}
                        enableEdit={enableEdit}
                        enableSelect={!!inputId}
                        requireParentId={requireParentId}
                        searchConfig={{
                          objectName: objectName,
                          labelField: titleField,
                          descriptionFields: descriptionFields
                        }}
                        newRecordLayout={{ ...newRecordLayout, objectName }}
                        onSelectItem={selectItem}
                        onCreateNewRecord={createNewRecord}
                        onLinkRecord={(linkItem) => handleLinkNewRecord(linkItem, index)}
                        onOpenEdit={(e) => setIsEditing(e)}
                        onUpdate={handleSuccessUpdate}
                        url={salesforceUser?.url}
                        isEditClicked={setIsEditClicked}
                      >
                      </RelatedItem>
                      {(index < items.length - 1) && <div style={{ paddingTop: "0.25rem" }}></div>}
                    </div>
                  )
                  :
                  !requireParentId && <em className="grid_center">No {label?.toLowerCase()} found</em>
                }
                {items && items.length > moreSize &&
                  <div className="grid_spread p-horizontal_x-small" style={{ marginTop: "0", marginBottom: "-1rem" }}>
                    <Button size="small" sx={{ textTransform: "lowercase", color: themeColors.primary }}
                      onClick={() => setMoreSize(items.length)}>
                      show all ({items.length})
                    </Button>
                    <Button size="small" sx={{ textTransform: "lowercase", color: themeColors.primary }}
                      onClick={() => setMoreSize(moreSize + 5)}>
                      show more...
                    </Button>
                  </div>
                }
              </Stack>
              {(requireParentId && (interactionId || caseId) && !isEditing && enableEdit) ?
                enableNew ?
                  <div className="p-horizontal_small">
                    <EditModeSection
                      newRecordLayout={{ ...JSON.parse(JSON.stringify({ ...newRecordLayout, objectName })) }}
                      onSuccessSave={handleSuccessUpdate} />
                  </div>
                  :
                  <div className="p-top_large p-horizontal_small">
                    <CreateRecord isCompact
                      {...JSON.parse(JSON.stringify({ ...newRecordLayout, objectName }))}
                      onSuccessSave={handleSuccessUpdate} />
                  </div>
                :
                <></>
              }
            </div>
          </ThemeProvider>
        </AccordionDetails>
      </Accordion>

      {!!createRecordConfig &&
        <div className={`${styles.newRecordContainer} ${topPosition}`} >
          {/* <div> */}
          <CreateRecord {...createRecordConfig}
            onCancel={handleCancel}
            onSuccessSave={handleCreateRecordSuccess}
          />
          {/* </div> */}
        </div>
      }
    </section >
  );
};

export default RelatedList;
