import { useContext } from 'react';
import { PageContext } from '../../context/context';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { capitalize } from '../../utils/helperFunctions';

import styles from './LoginItem.module.css';


/**
 * LoginItem is a reusable component that encapsulates a login-related component within an expandable accordion UI. 
 * It is designed to dynamically handle different login components passed as props, making it versatile for various login methods.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.Component - A React component that is rendered within the accordion. This component should handle specific login functionality.
 * @param {string} props.label - The label text displayed on the accordion summary, describing the encapsulated login functionality.
 * @returns {JSX.Element} A section with a styled accordion that expands to reveal the provided Component.
 *
 * @description
 * This component uses Material-UI's Accordion components to provide a visually consistent and accessible way to toggle the visibility of the login interface.
 * The appearance of the accordion is customized using theme colors from the PageContext, ensuring it adheres to the overall theme of the application.
 * The label provided in props is capitalized and displayed as the title of the accordion, indicating the purpose of the contained Component.
 * 
 * Usage of this component within an application would typically look like this:
 * <LoginItem Component={MyLoginComponent} label="My Login" />
 * where `MyLoginComponent` would be a component handling a specific type of login logic.
 */

const LoginItem = ({ Component, label }) => {
  const { themeColors } = useContext(PageContext);

  return (
    <section className={styles.loginWrapper}>
      <Accordion defaultExpanded disableGutters
        sx={{
          border: `1px solid ${themeColors.tertiary || "rgba(0, 0, 0, 0.2)"}`,
          borderRadius: "0!important",
          boxShadow: "none!important",
        }}>
        <AccordionSummary expandIcon={<ArrowDropUpIcon sx={{ color:themeColors.primary }} />} >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "0.8125rem",
              color: "#37474f",
              lineHeight: "1rem",
            }}>
            {capitalize(label)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderTop: "1px solid rgba(0, 0, 0, 0.2)"
          }}>
          <div style={{ fontSize: "0.725rem" }}>
            <Stack spacing={2}>

              <Component />

            </Stack>
          </div>
        </AccordionDetails>
      </Accordion>
    </section>
  );
};

export default LoginItem;
