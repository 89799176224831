import { useContext, useEffect, useState } from 'react';
import { PageContext } from '../../context/context';
import { TextField } from "@mui/material";
import { getTruncatedText } from '../../utils/helperFunctions';
import styles from './Inputs.module.css';
/**
 * InputText is a controlled input component that displays a text field with optional character limits and error handling.
 * It integrates with a context that provides shared functionality and data storage, and is designed to be reusable
 * across different forms and sections within the application.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.config - Configuration for the text input, including any attributes such as maxLength and defaultValue.
 * @param {string} props.id - Unique identifier for the text input, used for managing focus, data storage, and accessibility.
 * @param {string} props.label - Label text for the input field, displayed above the input.
 * @param {string} props.sectionId - Identifier for the form section that this input belongs to, used for managing state in context.
 * @param {Function} props.onChange - Callback function triggered on input change. It handles the input data processing and storage.
 * @returns {JSX.Element} A styled TextField component with controlled input functionality.
 *
 * @description
 * This component renders a TextField from Material-UI, applying dynamic styles and behaviors based on the passed `config`.
 * It uses a local state to manage the input's value and displays a character limit indicator if applicable.
 * Errors are managed locally within the component and are displayed as helper text beneath the input.
 *
 * Key Features:
 * - Debounced input handling to optimize performance and reduce update frequency.
 * - Character limit indication and enforcement, with visual feedback when the limit is exceeded.
 * - Integration with a global context (`PageContext`) for consistent theme application and shared function access.
 * - Dynamic handling of focus events to show and hide character limits.
 *
 * The component is especially useful in forms where inputs need to reflect real-time validation feedback and maintain a consistent look and feel.
 */
const InputText = (props) => {
  // console.log('from InputText', props);
  const { config, id, label, sectionId, onChange } = props || {};
  const { handleOnChangeWithDetail, valueStorage } = useContext(PageContext);

  const [onchangeTimeout, setOnchangeTimeout] = useState(null); //This is needed otherwise clearTimeout wont work
  const [errorMessage, setErrorMessage] = useState(null);
  const [showLimitIndicator, setShowLimitIndicator] = useState(false);
  const [limitIndicator, setLimitIndicator] = useState(() => {
    let result;
    if (config?.attributes?.maxLength > 0) {
      const count = !!config?.defaultValue ? config.defaultValue.length : 0;
      result = `${count} / ${config.attributes.maxLength}`;
    }
    return result;
  });

  /* START Internal Value */
  const [internalValue, setInternalValue] = useState(config?.defaultValue ? getTruncatedText(config.defaultValue, config) : '');
  useEffect(() => {
    if (valueStorage?.[sectionId]?.[id]?.value) {
      let newValue = getTruncatedText(valueStorage[sectionId][id].value, config);
      if (newValue !== internalValue) {
        generateLimitIndicator(newValue);
        checkCharacterLimit(newValue);
        setInternalValue(newValue);
      };
    };
  }, [valueStorage, sectionId, id]);
  /* END Internal Value */

  const checkCharacterLimit = (inputValue) => {
    if (config?.attributes?.maxLength > 0 && typeof inputValue === 'string' && inputValue?.length > config.attributes.maxLength) {
      //newValue = newValue.substring(0, config.attributes.maxLength);
      setErrorMessage('Input value exceeded character limit of ' + config.attributes.maxLength);
    } else {
      setErrorMessage(null);
    };
  };

  const generateLimitIndicator = (inputValue) => {
    if (config?.attributes?.maxLength > 0) {
      const count = !!inputValue ? inputValue.length : 0;
      setLimitIndicator(count + " / " + config.attributes.maxLength);
    }
  };

  const handleOnChange = (e) => {
    if (onchangeTimeout) clearTimeout(onchangeTimeout);
    setOnchangeTimeout(setTimeout(() => {
      checkCharacterLimit(e.target.value);
      const detail = {
        inputId: id,
        sectionId: sectionId,
        targetField: config?.targetField,
        type: config?.type,
        value: e.target.value
      };
      if (typeof onChange === 'function') {
        onChange(detail);
      } else {
        handleOnChangeWithDetail(detail);
      };
    }, 500));
    generateLimitIndicator(e.target.value);
    setInternalValue(e.target.value);
  };

  return (
    <div style={{ position: "relative" }}>
      {!!showLimitIndicator && !!limitIndicator && <div className={styles.characterLimitIndicator}>{limitIndicator}</div>}
      <TextField {...config?.attributes}
        id={id}
        style={{ width: "100%" }}
        inputProps={{
          inputMode: config.type,
          maxLength: config.attributes?.maxLength
        }}
        label={label}
        onChange={handleOnChange}
        value={internalValue}
        helperText={errorMessage}
        error={!!errorMessage}
        onFocus={() => setShowLimitIndicator(true)}
        onBlur={() => setShowLimitIndicator(false)}
      />
    </div>
  );
};

export default InputText;
