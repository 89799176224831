import { createContext, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Stack } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';

import styles from './loadingContext.module.css';


const LoadingContext = createContext();
let LOADING_STACK = 0;

/**
 * @summary Loading Context acts a store to house functions and state needed across application
 * @function
 * @namespace LoadingContext
 * @description used to house loading functions and global state for components.
 * To use, import { useContext } from 'react, also import { LoadingContext } from this location.
 * Then declare any function or state needed here such as in example below;
 * @example
 * const { isLoadingGlobal, setIsLoadingGlobal, loadingToast, } = useContext(LoadingContext);
 */
const LoadingProvider = ({ children }) => {
  const [loadingToast, setLoadingToast] = useState(null);
  const [isLoadingGlobal, setIsLoadingGlobal] = useState(false);

  /**
   * @summary Show progress for saving interations - currently unused
   * @function
   * @memberof LoadingContext
   * @description Used to set popup dialog with MUI circular progress spinner.
   * Loops through params and displays progress for saving and deleting interactions and links
   * @param {object} params params object
   * @param {number} deleteCounterLinks number of links to delete
   * @param {number} saveCounterInteraction number of interations to save
   * @param {number} saveCounterLinks number of links to save
   * @param {Array} delLinkErrors errors from delete request
   * @param {Array} interactionErrors errors from save interation request
   * @param {Array} saveLinkErrors errors from save link request
   */
  const openSaveInteractionProgress = (params) => {
    setLoadingToast(
      <Dialog open
        aria-labelledby="alert-dialog-title"
        disableEscapeKeyDown
        onClose={(e) => { e.preventDefault() }}
      >
        <DialogTitle>Save Progress</DialogTitle>
        <DialogContent className="grid_center" id="alert-dialog-title">
          <Stack spacing={1}>
            <div className="grid_wrap">
              { params.deleteCounterLinks === 0 ?
                <div className="size_1-1 grid">
                  <div><CheckCircleIcon className={styles.completeIcon} /></div>
                  <div className={styles.completeText}>Removed related items</div>
                </div>
                :
                <div className="size_1-1 grid">
                  <div><CircularProgress size={20} /></div>
                  <div className={styles.processingText}>Removing {params.deleteCounterLinks} related items</div>
                </div>
              }
              { params.delLinkErrors &&
                <ul className={styles.errorPanel}>
                  { params.delLinkErrors.map((errorMsg, index) => <li key={`link-loading-${index}`}>{errorMsg}</li>) }
                </ul>
              }
            </div>

            <div className="grid_wrap">
              { params.saveCounterInteraction === 0 ?
                <div className="size_1-1 grid">
                  <div><CheckCircleIcon className={styles.completeIcon} /></div>
                  <div className={styles.completeText}>Saved interaction</div>
                </div>
                :
                <div className="size_1-1 grid">
                  <div><CircularProgress size={20} /></div>
                  <div className={styles.processingText}>Saving interaction</div>
                </div>
              }
              { params.interactionErrors &&
                <ul className={styles.errorPanel}>
                  { params.interactionErrors.map((errorMsg, index) => <li key={`interactions-loading-${index}`}>{errorMsg}</li>) }
                </ul>
              }
            </div>

            <div className="grid_wrap">
              { params.saveCounterLinks === 0
                ? <div className="size_1-1 grid">
                    <div><CheckCircleIcon className={styles.completeIcon} /></div>
                    <div className={styles.completeText}>Added related items</div>
                  </div>
                : <div className="size_1-1 grid">
                    <div><CircularProgress size={20} /></div>
                    <div className={styles.processingText}>Adding {params.saveCounterLinks} related items</div>
                  </div>
              }
              { params.saveLinkErrors &&
                <ul className={styles.errorPanel}>
                  { params.saveLinkErrors.map((errorMsg, index) => <li key={`savedlinks-loading-${index}`}>{errorMsg}</li>) }
                </ul>
              }
            </div>
          </Stack>
        </DialogContent>

        { (params.deleteCounterLinks + params.saveCounterInteraction + params.saveCounterLinks === 0) &&
          <DialogActions>
            <Button onClick={() => { setLoadingToast(null) }}>Close</Button>
          </DialogActions>
        }
      </Dialog>
    );
  };

  /**
   * @summary Circular progress inside centered popup
   * @function
   * @memberof LoadingContext
   * @description Used to set popup dialog ontop of current template
   * This uses MUI for the circular progress spinner until loading ends
   * @param {boolean} [showLoadingSpinner] Weather to show loading spinner or not
   * @example
   * setIsLoading(true);
   */
    const setIsLoading = (loadingStatus) => {
      if (loadingStatus) {
        LOADING_STACK += 1;
      } else {
        LOADING_STACK -= 1;
      };

      if (LOADING_STACK > 0) {
        setLoadingToast(
          <Dialog open
            aria-labelledby="alert-dialog-title"
            disableEscapeKeyDown
            onClose={(e) => { e.preventDefault() }}
          >
            <DialogContent className="grid_center" id="alert-dialog-title">
              <CircularProgress />
            </DialogContent>
          </Dialog>
        );
      } else {
        setLoadingToast(null);
      };
    };

  return (
    <LoadingContext.Provider value={{
      isLoadingGlobal, setIsLoadingGlobal,
      loadingToast,
      openSaveInteractionProgress,
      setIsLoading
    }}>
      {children}
    </LoadingContext.Provider>
  );
};

export { LoadingContext, LoadingProvider };
