/**
 * @fileOverview used to render the corect svg or png icon into the requesting component
 * @namespace iconsMap
*/
import { ReactComponent as Body } from '../../assets/icons/ico-body.svg'; // original
import { ReactComponent as Company } from '../../assets/icons/ico-company.svg'; // original
import { ReactComponent as Contact } from '../../assets/icons/ico-contact.svg';
import { ReactComponent as Contacts } from '../../assets/icons/ico-contacts.svg'; // original
import { ReactComponent as Create } from '../../assets/icons/ico-create.svg';
import { ReactComponent as Date } from '../../assets/icons/ico-date.svg';
import { ReactComponent as Deals } from '../../assets/icons/icon-deals.svg'; // original
import { ReactComponent as Email } from '../../assets/icons/ico-email.svg'; // original
import { ReactComponent as Employees } from '../../assets/icons/ico-employees.svg'; // original
import { ReactComponent as Funds } from '../../assets/icons/icon-fund.svg'; // original

import { ReactComponent as InvestorFlowMark } from '../../assets/icons/ico-if-logomark.svg';
import { ReactComponent as Info } from '../../assets/icons/ico-info.svg'; // original
import { ReactComponent as LogEmail } from '../../assets/icons/ico-log-email.svg';
import { ReactComponent as LogMeeting } from '../../assets/icons/ico-log-meeting.svg';
import { ReactComponent as Person } from '../../assets/icons/ico-contact.svg'; // original
import { ReactComponent as RelatedDeals } from '../../assets/icons/ico-related-deals.svg';
import { ReactComponent as RelatedOpty } from '../../assets/icons/ico-related-opportunities.svg' // original
import { ReactComponent as Relationships } from '../../assets/icons/ico-relationships.svg'; // original
import { ReactComponent as Reminder } from '../../assets/icons/ico-reminder.svg'; // original
import { ReactComponent as Rerun } from '../../assets/icons/ico-rerun.svg';
import { ErrorOutlineOutlined } from '@mui/icons-material';


const iconsMap = {
  account: Company,
  body: Body,
  company: Company,
  contact: Contact,
  contacts: Contacts,
  create: Create,
  date: Date,
  error:ErrorOutlineOutlined,
  deals: Deals,
  email: Email,
  employees: Employees,
  funds: Funds,
  investorFlowMark: InvestorFlowMark,
  info: Info,
  log_email: LogEmail,
  log_meeting: LogMeeting,
  person: Person,
  related_deals: RelatedDeals,
  related_opty: RelatedOpty,
  relationships: Relationships,
  rerun: Rerun,
  reminder: Reminder
};

export default iconsMap;
