import { useContext } from 'react';
import { PageContext } from '../../context/context';
import { Button, darken, Typography } from '@mui/material';
import googleLogo from "../../assets/images/google_signin.png";
import LogoutIcon from '@mui/icons-material/Logout';
import LoginItem from './LoginItem';

import styles from './LoginItem.module.css';

/**
 * LoginGoogle provides a specific UI for handling login or logout actions for Google Workspace.
 * It uses a custom Component rendered within a reusable LoginItem component, displaying either the user's email when logged in
 * or a button to initiate the login process.
 *
 * @component
 * @returns {JSX.Element} A LoginItem component containing a specific setup for Google authentication.
 *
 * @description
 * This component manages Google authentication status, displaying the user's email if authenticated and providing
 * a button for login or logout. The button dynamically changes its appearance based on the authentication state:
 * - Shows a Google sign-in image and invites the user to log in when not authenticated.
 * - Turns into a logout button, colored according to the theme, when the user is authenticated.
 *
 * The `LoginItem` wrapper component is used to provide a consistent UI experience across different types of login components.
 * It takes a dynamic component (`Component`) that includes the specific logic and UI for Google login/logout functionalities,
 * and a label describing the login method, which in this case is "Google Workspace".
 *
 * The logic for handling the login and logout actions is encapsulated within `handleLogin`, which toggles the user's authentication state.
 * This interaction uses context-provided methods (`googleLogin` and `googleLogout`) to interface directly with the Google authentication API.
 */

const LoginGoogle = () => {
  const label = 'Google Workspace';
  const { googleAuthUser, googleLogin, googleLogout, themeColors } = useContext(PageContext);

  const Component = () => {
    return (
      <>
        { googleAuthUser && <Typography>{googleAuthUser.email}</Typography> }

        <div className="grid_center">
          <Button fullWidth variant={googleAuthUser ? "contained" : "text"}
            sx={ googleAuthUser ? {backgroundColor:themeColors.primary, '&:hover': {
              backgroundColor:darken(themeColors.primary, 0.1)
            }} : {} }
            size={googleAuthUser ? "medium" : "small"}
            className={!googleAuthUser ? styles.btnWrapper : ''}
            startIcon={googleAuthUser ? <LogoutIcon /> : null}
            onClick={() => handleLogin(label)}>
            { !googleAuthUser
              ? <img className={styles.btnImg} src={googleLogo} alt={'sign in with google'} />
              : `${label} Logout`
            }
          </Button>
        </div>
      </>
    );
  };

  const handleLogin = () => {
    googleAuthUser ? googleLogout() : googleLogin();
  };

  return (
    <LoginItem label={label} Component={Component} />
  );
};

export default LoginGoogle;
