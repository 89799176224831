import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

/**
 * CustomTable is a component that renders a table with customized styles for cells and rows using Material-UI components.
 * It is designed to display tabular data with optional nested tables within cells for complex data structures.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.config - Configuration object containing data for the table rows, each row potentially containing nested data.
 * @param {string} props.id - Unique identifier for the table, used primarily for accessibility and component tracking.
 * @param {string} props.label - Descriptive label for the table, not directly used in the component rendering.
 * @param {string} props.sectionId - Identifier for the form section that this table belongs to, typically used for managing state in larger forms.
 * @returns {JSX.Element} A Material-UI TableContainer component containing a Table with customized cells and rows.
 *
 * @description
 * The CustomTable leverages Material-UI's styling capabilities to apply custom styles to table cells and rows, enhancing
 * the visual distinction of table headers and body cells. The table structure supports displaying both simple and complex data entries:
 * - Simple data entries are displayed directly within the table's rows.
 * - Complex data entries, identified as arrays, trigger the rendering of nested tables within a cell.
 *
 * Key Features:
 * - StyledTableCell: Custom styles for TableCell components that differentiate between header and body cells.
 * - StyledTableRow: Applies striped styling to rows and removes the last border for a cleaner look.
 * - Nested Table Support: Allows embedding tables within tables for hierarchical data presentation, handled recursively when needed.
 *
 * This component is particularly useful in scenarios where data needs to be presented in a structured table format with clear differentiation
 * between rows and potentially nested data. The customization and flexibility provided make it suitable for various data display requirements.
 */

const CustomTable = ({ config, id, label, sectionId}) => {
  // console.log('custome table props', config, id, label, sectionId);

  const CustomTableBody = () => {
    return (
      <TableBody >
      { config?.rows.map((row, index) => (
        Array.isArray(row?.value)
          ? <StyledTableRow key={`${row?.id}-${row.label}-${index}`}>
              <StyledTableCell component="th" scope="row" data-level={row.level}>{row.label}</StyledTableCell>
              <StyledTableCell align="left">
                <Table>
                  <TableBody>
                    { row.value.map((item, key) => (
                      <StyledTableRow key={`nested-${item.label}-${key}-${item?.id}`}>
                        <StyledTableCell component="th" scope="row">{item.label}</StyledTableCell>
                        <StyledTableCell align="left">{item.value}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </StyledTableCell>
            </StyledTableRow>

          : <StyledTableRow key={`${row?.id}-${row.label}-${index}`}>
              <StyledTableCell component="th" scope="row" data-level={row.level}>{row.label}</StyledTableCell>
              <StyledTableCell align="left">{row.value}</StyledTableCell>
            </StyledTableRow>
      ))}
      </TableBody>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 250 }} aria-label="customized table">
        <CustomTableBody />
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
