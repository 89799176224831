import { useContext } from 'react';
import { PageContext } from '../context/context';
import { constants } from '../utils/constants';
import CustomAvatar from '../components/User/CustomAvatar';
import { Stack, Typography } from "@mui/material";
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';

import styles from './templates.module.css';

/**
 * @summary No item selected screen
 * @interface NoItemSelected
 * @description Screen shown when no email or meeting is selected and addin is open
*/
const NoEmailSelected = ({ externalId, id, user }) => {
  const { salesforceUser, setUserMenuAnchorEl, showUserMenu, setShowUserMenu, themeColors } = useContext(PageContext);
  const isOutlook = constants.EMAIL_CLIENT === 'outlook' ? true : false;

  const runAndroidTest = () => {
    const customStringify = function (v) {
      const cache = new Set();
      return JSON.stringify(v, function (key, value) {
        if (typeof value === 'object' && value !== null) {
          if (cache.has(value)) {
            // Circular reference found
            try {
              // If this value does not reference a parent it can be deduped
             return JSON.parse(JSON.stringify(value));
            }
            catch (err) {
              // discard key if value cannot be deduped
             return;
            }
          }
          // Store value in our set
          cache.add(value);
        }
        return value;
      });
    };
    return (
      <div className={styles.testStringify}>
        <h2>Android and iOS viewable only</h2>
        <p>external id</p>
        {externalId}
        <p>mailbox item</p>
        {JSON.stringify(Office?.context?.mailbox?.initialData, null, " ")}
        <p>does the mailbox item show below?</p>
        {customStringify(Office?.context?.mailbox?.item, null, " ")}
        <a className="btn" href="javascript:(function(F,i,r,e,b,u,g,L,I,T,E){if(F.getElementById(b))return;E=F[i+'NS']&&F.documentElement.namespaceURI;E=E?F[i+'NS'](E,'script'):F[i]('script');E[r]('id',b);E[r]('src',I+g+T);E[r](b,u);(F[e]('head')[0]||F[e]('body')[0]).appendChild(E);E=new%20Image;E[r]('src',I+L);})(document,'createElement','setAttribute','getElementsByTagName','FirebugLite','4','firebug-lite.js','releases/lite/latest/skin/xp/sprite.png','https://cdnjs.cloudflare.com/ajax/libs/firebug-lite/1.4.0/','#startOpened');">Debug Console</a>
      </div>
    )
  };

  return (
    <>
      { isOutlook &&
        <div className={styles.outlookNavNoEmail}>
          <CustomAvatar
            id={id}
            onClick={(event) => {
              setUserMenuAnchorEl(event.currentTarget);
              setShowUserMenu(!showUserMenu);
            }}
            salesforceUser={salesforceUser?.displayName}
            themeColor={themeColors.tertiary}
            user={user}
          />
        </div>
      }

    {/* { isOutlook && (Office?.context?.diagnostics?.platform === 'iOS' || Office?.context?.diagnostics?.platform === 'Android') && runAndroidTest() } */}

      <Stack spacing={1} sx={ {alignItems: "center"} }>
        <MarkunreadMailboxIcon
          className="grid_center"
          color="primary"
          sx={{
            color: "#ffcc80",
            fontSize: "9rem"
          }} />
        <Typography
          variant="subtitle2"
          sx={{
            display: "flex",
            justifyContent: "center"
          }}>
          <b>Select an item to log</b>
        </Typography>
        <Typography
          variant="caption"
          sx={{
            display: "flex",
            justifyContent: "center"
          }}>
          Nothing is selected
        </Typography>
      </Stack>
    </>
  );
};

export default NoEmailSelected;
