/**
 * @fileOverview components to build out the user avatar and menu inside a modal
 * @module components/User
 * @require @mui/material
*/
import { Popover } from "@mui/material";
import UserMenu from './UserMenu';

/**
 * @summary creates a custom user avatar
 * @description creates a custom user avatar with set color from salesforce config or random color created by user initals
 * @function
 * @memberof module:components/User
 * @param {string} props.anchor - element to anchor menu against
 * @param {string} props.id - component id
 * @param {function} props.open - function to handle open and closing of menu
 * @returns {component}
*/
const Modal = (props) => {
  const { anchor, id, open } = props || {};
  const anchorOrigin = { vertical: 'bottom', horizontal: 'right' };
  const PaperProps = { sx: { marginTop: '3px', transform: "translateX(-16px)!important" } };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchor}
      anchorOrigin={anchorOrigin}
      PaperProps={PaperProps}
    >
      <UserMenu />
    </Popover>
  );
};

export default Modal;
