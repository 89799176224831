/**
 * @fileOverview builds attachment pannel used in intro screen
 * @module components/AttachmentsPanel2
 * @requires axios
 * @requires mui
*/
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { PageContext } from '../../context/context';
import { DmlContext } from '../../context/dmlContext';
import { constants } from '../../utils/constants';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Stack, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CircularProgress from '@mui/material/CircularProgress';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import IconCheckbox from '../IconButtons/IconCheckbox';

import MailIcon from '@mui/icons-material/Mail';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import WorkIcon from '@mui/icons-material/Work';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import fileIconsMap from '../../utils/maps/fileIconsMap';

import styles from './Attachments.module.css';

/**
 * @summary creates a attachments pannel
 * @description similar to AttachmentPanel, but will now support case, email, and meeting
 * @function
 * @memberof module:components/AttachmentsPanel2
 * @returns {component}
*/
const AttachmentsPanel2 = (props) => {
  const { enableCase, enableEmail, enableMeeting, selectByDefaultCase, selectByDefaultEmail, selectByDefaultMeeting, hideSaveButton } = props || {};
  const [attachmentList, setAttachmentList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showSelectAll, setShowSelectAll] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [warningMessage, setWarningMessage] = useState(null);
  const {
    caseConfig,
    caseId,
    interactionId,
    logError,
    logType,
    payload,
    salesforceUser,
    themeColors
  } = useContext(PageContext);
  const {
    attachmentError,
    saveContentVersions2,
    unsavedAttachments, setUnsavedAttachments
  } = useContext(DmlContext);

  useEffect(() => {
    if (payload && salesforceUser) {
      //console.log("process new payload");
      const attList = [];
      if (constants.EMAIL_CLIENT === 'outlook') {
        payload.attachments?.forEach((att) => {
          if (!!att.name && !!att.id) {
            const fileType = getExtension(att?.name);
            const fileIcon = fileIconsMap[fileType] ? fileIconsMap[fileType] : fileIconsMap.unknown;
            attList.push({
              googleId: att.id,
              fileName: att.name.replaceAll('+', '_'),
              icon: fileIcon,
              isInline: att.isInline,
              mimeType: att.contentType
            });
          }
        });
      } else {
        if (payload.attachments?.length > 0) {
          payload.attachments?.forEach((att) => {
            if (!!att.title && !!att.fileId) {
              const fileType = att.title.split(".").pop();
              const fileIcon = fileIconsMap[fileType] ? fileIconsMap[fileType] : fileIconsMap.unknown;
              attList.push({
                googleId: att.fileId,
                fileName: att.title.replaceAll('+', '_'),
                icon: fileIcon,
                mimeType: att.mimeType
              });
            }
          });
        } else if (payload.parts?.length > 0) {
          payload.parts?.forEach((att) => {
            if (!!att.filename && !!att.body?.attachmentId) {
              const fileType = att.filename.split(".").pop();
              const fileIcon = fileIconsMap[fileType] ? fileIconsMap[fileType] : fileIconsMap.unknown;
              attList.push({
                googleId: att.body.attachmentId,
                fileName: att.filename.replaceAll('+', '_'),
                icon: fileIcon,
                mimeType: att.mimeType
              });
            }
          });
        } else {
          setUnsavedAttachments(null);
          setAttachmentList(null);
        }
      }

      if (interactionId || caseId) {
        setUnsavedAttachments(null);
        getExistingAttachments(attList);
      } else {
        const unsavedList = [];
        attList.forEach(att => {
          if (unsavedAttachments?.length > 0) {
            unsavedAttachments?.some(oldAtt => {
              if (att.fileName === oldAtt.fileName) {
                if (enableCase) {
                  att.uploadToCase = oldAtt.uploadToCase;
                }
                if (enableEmail || enableMeeting) {
                  att.uploadToInteraction = oldAtt.uploadToInteraction;
                }
                return true;
              }
            });
          } else {
            att.uploadToCase = (enableCase && selectByDefaultCase);
            att.uploadToInteraction = (enableEmail && selectByDefaultEmail) || (enableMeeting && selectByDefaultMeeting);
          }
          if (att.uploadToCase || att.uploadToInteraction) {
            unsavedList.push(att);
          }
        });
        setUnsavedAttachments(JSON.parse(JSON.stringify(unsavedList)));
        setAttachmentList(attList);
        setShowSelectAll(attList.length > 1);
        setShowSaveButton(false);
      }
    }
  }, [payload, salesforceUser]);

  useEffect(() => {
    if (interactionId || caseId) {
      setUnsavedAttachments(null);
      if (payload && salesforceUser && attachmentList?.length > 0) {
        getExistingAttachments(JSON.parse(JSON.stringify(attachmentList)), true);
      }
    }
  }, [interactionId, caseId]);

  const getExtension = (filename) => {
    return filename.split('.').pop();
  };

  /*
  This function performs 2 query callouts in order to map detected attachments to existing files in Salesforce.
  1. Query for ContentDocumentLink to determine if any file has previously linked to current Case or Interaction
  2. Query for ContentVersion to determine which attachment has been uploaded and has it been shared with Case or Interaction
  */
  const getExistingAttachments = async (attList, turnDefaultOff) => {
    if (!interactionId && !caseId) return;
    //const response = await axios.post(url, { data: JSON.stringify(soqlString) }, options);

    setIsLoading(true);
    const fileNames = [];
    attList.forEach(att => {
      fileNames.push(att.fileName);
    });
    const linkEntityIds = [];
    if (interactionId) linkEntityIds.push(interactionId);
    if (caseId) linkEntityIds.push(caseId);

    const url = `${constants.REACT_SERVER_URL}/api/v2/query/soql/${salesforceUser?.account}/${salesforceUser?.instance}/${salesforceUser?.accessToken}/${salesforceUser?.refreshToken}`;
    const options = { headers: { 'Content-Type': 'application/json' } };

    let response;
    // retrieve ContentDocumentLink
    let soqlString = `SELECT ContentDocumentId,LinkedEntityId FROM ContentDocumentLink WHERE LinkedEntityId IN ('${linkEntityIds.join("','")}')`;
    try {
      response = await axios.post(url, { data: JSON.stringify(soqlString) }, options);
    } catch (error) {
      logError({
        functionName: 'getExistingAttachments',
        error: error,
        additionalNotes: '',
        displayedToUser: false,
        level: 'error'
      });
      console.warn('error fetching ContentDocumentLink data', error);
    };

    if (response?.data?.records?.length > 0) {
      const cdlToEntityMap = {};

      response.data.records.forEach(record => {
        // console.log("ContentDocumentLink", record);
        if (!cdlToEntityMap[record.ContentDocumentId]) {
          cdlToEntityMap[record.ContentDocumentId] = new Set();
        }
        cdlToEntityMap[record.ContentDocumentId].add(record.LinkedEntityId);
      });

      // retrieve ContentVersion
      soqlString = `SELECT ContentDocumentId,PathOnClient,Title FROM ContentVersion`
        + ` WHERE ContentDocumentId IN ('${Object.keys(cdlToEntityMap).join("','")}') AND PathOnClient IN ('${fileNames.join("','")}')`;
      try {
        response = await axios.post(url, { data: JSON.stringify(soqlString) }, options);
      } catch (error) {
        logError({
          functionName: 'getExistingAttachments',
          error: error,
          additionalNotes: '',
          displayedToUser: false,
          level: 'error'
        });
        console.warn('error fetching ContentVersion data', error);
      };

      if (!response?.data?.[0]?.errors || response?.data?.[0]?.errors?.length === 0) {
        // iterate over the list of ContentVersion to match file name to ContentDocumentId
        //console.log("after query attList", attList);
        attList.forEach(att => {
          response.data?.records?.forEach(record => {
            // console.log("ContentVersion", record);
            if (att.fileName === record.PathOnClient) {
              att.savedToCase = cdlToEntityMap[record.ContentDocumentId].has(caseId);
              att.savedToInteraction = cdlToEntityMap[record.ContentDocumentId].has(interactionId);
              att.docId = record.ContentDocumentId;
              att.fileTitle = record.Title;
            }
          });
          unsavedAttachments?.some(oldAtt => {
            if (att.fileName === oldAtt.fileName) {
              if (enableCase) {
                att.uploadToCase = oldAtt.uploadToCase && !att.savedToCase;
              }
              if (enableEmail || enableMeeting) {
                att.uploadToInteraction = oldAtt.uploadToInteraction && !att.savedToInteraction;
              }
              return true;
            }
          });
          if (turnDefaultOff) {
            att.uploadToCase = false;
            att.uploadToInteraction = false;
          }
        });
      }
    }
    let hasUnsaved = false;
    attList.forEach(att => {
      if (!att.savedToCase || !att.savedToInteraction) {
        hasUnsaved = true;
      }
    });
    setAttachmentList(attList);
    setShowSaveButton(hasUnsaved);
    setShowSelectAll(hasUnsaved);
    setIsLoading(false);
  };

  const handleFileUpload = async () => {
    setIsLoading(true);
    await saveContentVersions2();
    getExistingAttachments(attachmentList);
  };

  const handleSelectAll = (e) => {
    const newUnsaved = [];
    const newList = [...attachmentList];
    newList.forEach(att => {
      let isSelected = false;
      if (enableCase && !att.savedToCase) {
        att.uploadToCase = true;
        isSelected = true;
      }
      if ((enableEmail || enableMeeting) && !att.savedToInteraction) {
        att.uploadToInteraction = true;
        isSelected = true;
      }
      if (isSelected && (!att.savedToCase || !att.savedToInteraction)) {
        newUnsaved.push(att);
      }
    });
    checkForMissingLinkEntityId(newUnsaved);
    setUnsavedAttachments(newUnsaved);
    setAttachmentList(newList);
  };

  const handleUnselectAll = (e) => {
    setUnsavedAttachments(null);
    const newList = [...attachmentList];
    newList.forEach(att => {
      if (!att.savedToCase) {
        att.uploadToCase = false;
      }
      if (!att.savedToInteraction) {
        att.uploadToInteraction = false;
      }
    });
    setWarningMessage(null);
    setAttachmentList(newList);
  };

  const toggleItem = (detail) => {
    // console.log("toggle attachment item", detail);
    const newList = [...attachmentList];
    const newUnsaved = [];
    newList[detail.index][detail.name] = detail.value;
    newList.forEach(att => {
      if ((att.uploadToCase && !att.savedToCase) || (att.uploadToInteraction && !att.savedToInteraction)) {
        newUnsaved.push(att);
      }
    });
    checkForMissingLinkEntityId(newUnsaved);
    setUnsavedAttachments(newUnsaved);
    setAttachmentList(newList);
  };

  const checkForMissingLinkEntityId = (selectedAttachments => {
    let missingCaseId = false;
    let missingInteractionId = false;
    if (!hideSaveButton && showSaveButton) {
      selectedAttachments?.forEach(att => {
        if (enableCase && att.uploadToCase && !caseId) {
          missingCaseId = true;
        }
        if ((enableEmail || enableMeeting) && att.uploadToInteraction && !interactionId) {
          missingInteractionId = true;
        }
      });
    }
    if (missingCaseId) {
      setWarningMessage(`The Save button below will ignore selected attachments for ${caseConfig.buttonLabel} because ${caseConfig.buttonLabel} does not exist for this ${logType}.`);
    } else if (missingInteractionId) {
      setWarningMessage(`The Save button below will ignore selected attachments for Interaction because this ${logType} has not been logged.`);
    } else {
      setWarningMessage(null);
    }
  });

  const getColumnCount = () => {
    //console.log("Attachment options", enableCase, enableEmail, enableMeeting);
    let columnCount = 0;
    if (enableCase) columnCount++;
    if (enableEmail) columnCount++;
    if (enableMeeting) columnCount++;

    return columnCount;
  };

  return (
    <section className={styles.attachments}>
      <Accordion defaultExpanded disableGutters
        sx={{
          border: `1px solid ${themeColors.tertiary || "rgba(0, 0, 0, 0.2)"}`,
          borderRadius: "0!important",
          boxShadow: "none!important",
        }}>
        <AccordionSummary
          expandIcon={<ArrowDropUpIcon sx={{ color: themeColors.primary }} />}
          sx={{ padding: "0 0.5rem" }}>
          <div style={{ display: "flex" }}>
            <AttachmentIcon sx={{ marginRight: "0.5rem", fill: themeColors.tertiary || "#37474f" }} />
            <Typography
              sx={{
                margin: "auto",
                fontWeight: "bold",
                fontSize: "0.875rem",
                color: "#37474f",
                lineHeight: "1rem",
              }}>
              Attachments
            </Typography>
            {isLoading &&
              <CircularProgress size={20} sx={{ marginLeft: "1rem" }} />
            }
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "0.5rem 0 1rem 0",
            borderTop: "1px solid rgba(0, 0, 0, 0.2)"
          }}>
          {attachmentList && attachmentList.length > 0 ?
            <div className="p-left_large" style={{ fontSize: "0.725rem" }}>
              {showSelectAll &&
                <div className="p-bottom_small grid_reverse size_1-1" style={{ fontSize: "0.65rem" }}>
                  <div className={styles.selectAllButton} onClick={handleSelectAll}>Select All</div>
                  <div className={styles.unselectAllButton} onClick={handleUnselectAll}>Unselect All</div>
                </div>
              }

              {attachmentError &&
                <div style={{ paddingRight: "1rem", paddingBottom: "0.5rem" }}>
                  <Alert severity="error">
                    Failed to save attachments!
                  </Alert>
                </div>
              }

              <Stack spacing={1}>
                {attachmentList.map((att, index) =>
                  <div key={`${index}-att`} className="size_1-1">
                    <div className="grid size_1-1 p-top_xx-small" style={{ position: "relative" }}>
                      <Box component="img"
                        src={att.icon}
                        sx={{
                          height: "26px",
                          marginRight: "0.75rem",
                          opacity: (att.savedToCase || att.savedToInteraction) ? "1" : "0.4"
                        }} />

                      <div className={styles.fileNameContainer} data-column-count={getColumnCount()}>
                        {(att.savedToCase || att.savedToInteraction) ?
                          <a href={`${salesforceUser?.url}/${att.docId}`} target={'_blank'} rel="noreferrer"><b style={{ color: "#1976d2" }}>{att.fileTitle}</b></a>
                          :
                          <span style={{ color: (attachmentError && attachmentError[att.fileName]) ? "#d32f2f" : "unset" }}>{att.fileName}</span>
                        }
                      </div>

                      <div>
                        <div className={styles.selectorsContainer} data-column-count={getColumnCount()}>
                          {enableCase &&
                            <div style={{ paddingRight: "4px" }}>
                              <IconCheckbox name="uploadToCase" index={index}
                                checked={att.uploadToCase || att.savedToCase}
                                disabled={att.savedToCase}
                                onChange={toggleItem}
                                checkedIcon={<WorkIcon sx={{ color: themeColors.secondary || themeColors.primary }} />}
                                uncheckedIcon={<WorkOutlineOutlinedIcon />}
                              />
                            </div>
                          }
                          {enableEmail &&
                            <div>
                              <IconCheckbox name="uploadToInteraction" index={index}
                                checked={att.uploadToInteraction || att.savedToInteraction}
                                disabled={att.savedToInteraction}
                                onChange={toggleItem}
                                checkedIcon={<MailIcon sx={{ color: themeColors.secondary || themeColors.primary }} />}
                                uncheckedIcon={<MailOutlineIcon />}
                              />
                            </div>
                          }
                          {enableMeeting &&
                            <div>
                              <IconCheckbox name="uploadToInteraction" index={index}
                                checked={att.uploadToInteraction || att.savedToInteraction}
                                disabled={att.savedToInteraction}
                                onChange={toggleItem}
                                checkedIcon={<MeetingRoomIcon sx={{ color: themeColors.secondary || themeColors.primary }} />}
                                uncheckedIcon={<MeetingRoomOutlinedIcon />}
                              />
                            </div>
                          }
                        </div>
                      </div>
                    </div>

                    {attachmentError && attachmentError[att.fileName] &&
                      <div style={{ paddingTop: "0.125rem", paddingRight: "1rem", paddingBottom: "0.25rem", color: "#d32f2f" }}>
                        <ul style={{ margin: 0, paddingLeft: 0, listStyleType: "none" }}>
                          {attachmentError[att.fileName].map((error, errorIndex) =>
                            <li key={`att_error_${index}_${errorIndex}`}>
                              {!!error.fields && <span>{error.fields}: </span>}<span>{error.message}</span>
                            </li>
                          )}
                        </ul>
                      </div>
                    }

                  </div>

                )}
              </Stack>
              {!!warningMessage &&
                <div className={styles.warningMessage}>
                  {warningMessage}
                </div>
              }
              {showSaveButton && !hideSaveButton &&
                <div className="size_1-1 p-top_small grid_reverse">
                  <Button size="small" color="primary" variant="contained"
                    sx={{ marginRight: "0.5rem" }}
                    disabled={isLoading}
                    startIcon={<FileUploadIcon />}
                    onClick={handleFileUpload}>
                    Save
                  </Button>
                </div>
              }
            </div>
            :
            <div className='grid_center'><i style={{ fontSize: "0.725rem" }}>No attachment found</i></div>
          }
        </AccordionDetails>
      </Accordion >
    </section >
  );
};

export default AttachmentsPanel2;
