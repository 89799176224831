/**
 * @fileOverview functions used to setup app per salesforce config
 * @namespace configFunctions
 * @requires axios
*/
import axios from 'axios';
import { constants } from "./constants";

const options = { headers: { 'Content-Type': 'application/json' } };

/**
 * @summary ...
 * @function
 * @memberof configFunctions
 * @description ...
 * @param {object} inputConfig - ...
 * @param {object} dfr - ...
 * @param {string} interactionRecordTypeId - ...
*/
const applySchemaData = (inputConfig, dfr, interactionRecordTypeId) => {
  if (dfr) {
    // Put schemas data into attributes
    if (inputConfig.type === 'picklist') {
      const optionMap = {};
      dfr.options.forEach(o => {
        optionMap[o.value] = o;
      });
      try {
        inputConfig.attributes.options.forEach(o => {
          if (optionMap[o.value]) {
            o.ctrlValues = [...optionMap[o.value].ctrlValues];
          }
        });
      } catch (error) {
        // console.log(inputConfig, dfr, interactionRecordTypeId);
        console.log('config issue with above picklist', error);
      };
      inputConfig.recordTypeId = interactionRecordTypeId;
    } else {
      inputConfig.attributes = { ...inputConfig.attributes, ...dfr };
    };
  };
};

/**
 * @summary ...
 * @function
 * @memberof configFunctions
 * @description ...
 * @param {array} config - ...
 * @param {array} required - ...
 * @param {string} logType - ...
*/
const checkForRequiredFields = (config, required, logType) => {
  config.forEach(item => {
    if (item?.inputFields?.length > 0) {
      item.inputFields.forEach(field => {
        if (field?.attributes.required) {
          field.parentId = item.id;
          field.logType = logType;
          required.push(field)
        };
      });
    };
  });
  return required;
};

/**
 * @summary ...
 * @function
 * @memberof configFunctions
 * @description ...
 * @param {object} appSetting - ...
 * @param {object} salesforceUser - ...
*/
const getRecordTypeMapSetting = async (appSettings, salesforceUser) => {
  const soqlString = `SELECT+Id,CT_UI__Contact_External_Record_Type__c,CT_UI__Contact_Internal_Record_Type__c,SetupOwnerId`
    + `+FROM+CT_UI__RecordType_Map__c+WHERE+SetupOwnerId+=+'${salesforceUser.org}'`;
  const url = `${constants.REACT_SERVER_URL}/api/v2/query/soql/${salesforceUser?.account}/${salesforceUser?.instance}/${salesforceUser?.accessToken}/${salesforceUser?.refreshToken}?q=${soqlString}`;
  try {
    const response = await axios.get(url, options);
    if (response?.data?.records?.length >= 1) {
      const rtmRecord = response.data.records[0];
      if (!!rtmRecord?.CT_UI__Contact_Internal_Record_Type__c) {
        const rtSet = new Set(rtmRecord.CT_UI__Contact_Internal_Record_Type__c.split(/\s*;\s*/g));
        rtSet.delete("");
        appSettings.internal = [...rtSet];
      }
      if (!!rtmRecord?.CT_UI__Contact_External_Record_Type__c) {
        const rtSet = new Set(rtmRecord.CT_UI__Contact_External_Record_Type__c.split(/\s*;\s*/g));
        rtSet.delete("");
        appSettings.external = [...rtSet];
      }
    } else {
      console.warn("No CT_UI__RecordType_Map__c was found for this org.");
    }
  } catch (e) {
    console.warn("Error getting RecordType Map getRecordTypeMapSetting", e);
  };
};

/**
 * @summary ...
 * @function
 * @memberof configFunctions
 * @description ...
 * @param {array} locationIds - ...
 * @param {object} appSetting - ...
 * @param {object} salesforceUser - ...
*/
const getRecordTypeMapSettingLegacy = async (locationIds, appSettings, salesforceUser) => {
  const soqlString = `SELECT+Id,CT_UI__Contact_External_Record_Type__c,CT_UI__Contact_Internal_Record_Type__c,SetupOwnerId`
    + `+FROM+CT_UI__RecordType_Map__c+WHERE+SetupOwnerId+IN+('${locationIds.join("','")}')`;
  const url = `${constants.REACT_SERVER_URL}/api/v2/query/soql/${salesforceUser?.account}/${salesforceUser?.instance}/${salesforceUser?.accessToken}/${salesforceUser?.refreshToken}?q=${soqlString}`;

  try {
    const response = await axios.get(url, options);
    const rtmLength = response?.data?.records?.length;
    let rtmRecord;
    if (rtmLength > 1) {
      const rtmMap = {};
      response.data.records.forEach(record => {
        rtmMap[record.SetupOwnerId] = record;
      });
      rtmRecord = rtmMap[salesforceUser.id] || rtmMap[salesforceUser.org];
    } else if (rtmLength === 1) {
      rtmRecord = response.data.records[0];
    }
    if (!!rtmRecord?.CT_UI__Contact_Internal_Record_Type__c) {
      const rtSet = new Set(rtmRecord.CT_UI__Contact_Internal_Record_Type__c.split(/\s*;\s*/g));
      rtSet.delete("");
      appSettings.internal = [...rtSet];
    }
    if (!!rtmRecord?.CT_UI__Contact_External_Record_Type__c) {
      const rtSet = new Set(rtmRecord.CT_UI__Contact_External_Record_Type__c.split(/\s*;\s*/g));
      rtSet.delete("");
      appSettings.external = [...rtSet];
    }
    const data = { appSettings: appSettings, locationIds: locationIds, salesforceUser: salesforceUser };
    return data;
  } catch (e) {
    console.log("Failed to retrieve CT_UI__RecordType_Map__c from current profile and org");
    // console.warn("Error getting RecordType Map: getRecordTypeMapSettingLegac", e);
    return 'error';
  };
};

/**
 * @summary Retrieve settings that are personalized for user or profile
 * @function
 * @memberof configFunctions
 * @description ...
 * @param {object} salesforceUser - ...
*/
const getSalesforceAppSettings = async (salesforceUser) => {
  const appSettings = {};
  if (!salesforceUser?.id) return appSettings;

  const soqlString = `SELECT+Id,TimeZoneSidKey,CT_PE__Pipe_Profile__c+FROM+User+WHERE+Id='${salesforceUser.id}'`;
  const url = `${constants.REACT_SERVER_URL}/api/v2/query/soql/${salesforceUser?.account}/${salesforceUser?.instance}/${salesforceUser?.accessToken}/${salesforceUser?.refreshToken}?q=${soqlString}`;
  let pipeProfile;
  try {
    const response = await axios.get(url, options);
    pipeProfile = response?.data?.records?.[0]?.[`CT_PE__Pipe_Profile__c`];
  } catch (e) {
    console.warn("Error getting Profile", e);
    if (e?.response?.status === 401) {
      // console.log('get new salesforce access token');
      return { error: 401 };
    }
  };
  appSettings.pipeProfile = !!pipeProfile ? pipeProfile : 'Default';
  await getRecordTypeMapSetting(appSettings, salesforceUser);

  return appSettings;
};

/**
 * @summary ...
 * @function
 * @memberof configFunctions
 * @description ...
 * @param {object} salesforceUser - ...
*/
const getSalesforceAppSettingsLegacy = async (salesforceUser) => {
  const appSettings = {};
  if (!salesforceUser?.id) return appSettings;

  const locationIds = [salesforceUser.id, salesforceUser.org];
  try {
    const continueSFCall = await getRecordTypeMapSettingLegacy(locationIds, appSettings, salesforceUser);
    if (continueSFCall !== 'error') {
      return await retrieveUserSpecificConfig(continueSFCall);
    } else {
      return appSettings;
    };
  } catch (e) {
    console.warn("Error getting Profile", e);
    if (e?.response?.status === 401) {
      console.log('get new token here');
      return { error: 401 };
    }
  };
  return appSettings;
};

/**
 * @summary ...
 * @function
 * @memberof configFunctions
 * @description ...
 * @param {object} inputConfig - ...
*/
const parseSourceField = (inputConfig) => {
  if (inputConfig.sourceField) {
    let parsedSourceField = '';
    inputConfig.sourceField.split(";").some(sf => {
      if (constants.EMAIL_CLIENT === "gmail") {
        if (!sf.includes("outlook.")) {
          parsedSourceField = sf.trim().replace("gmail.", "");
          return true;
        }
      } else {
        if (sf.includes("outlook.")) {
          parsedSourceField = sf.trim().replace("outlook.", "");
          return true;
        }
      }
      return false;
    });
    inputConfig.sourceField = parsedSourceField;
  };
};

/**
 * @summary ...
 * @function
 * @memberof configFunctions
 * @description ...
 * @param {object} recordLayoutsJSON - ...
 * @param {array} layoutSections - ...
*/
const populateFieldMap = (recordLayoutsJSON, layoutSections) => {
  const tempFieldMap = {};
  layoutSections?.forEach(sectionConfig => {
    if (sectionConfig.inputFields) {
      sectionConfig.inputFields.forEach(inputConfig => {
        const sourceField = inputConfig.sourceField;
        if (sourceField) {
          if (!tempFieldMap[sourceField]) {
            tempFieldMap[sourceField] = [];
          }
          const tempConfig = {
            sectionId: sectionConfig.id,
            inputId: inputConfig.id,
            targetField: inputConfig.targetField,
            type: inputConfig.type
          };
          if (inputConfig.autoTruncate) {
            tempConfig.autoTruncate = true;
            tempConfig.attributes = { maxLength: inputConfig?.attributes?.maxLength };
          }
          tempFieldMap[sourceField].push(tempConfig);
        }
        if (inputConfig.attributes?.newRecordLayout) {
          inputConfig.attributes.newRecordLayout = JSON.parse(recordLayoutsJSON)[inputConfig.attributes.newRecordLayout];
        };
      });
    };
  });
  return tempFieldMap;
};

/**
 * @summary ...
 * @function
 * @memberof configFunctions
 * @description ...
 * @param {object} celRecord - ...
 * @param {object} sfAppSettings - ...
 * @param {string} logType - ...
 * @param {string} contactObject - ...
 * @param {string} interactionRecordTypeId - ...
*/
const processChromeExtLayoutConfig = (celRecord, sfAppSettings, logType, contactObject, interactionRecordTypeId) => {
  let result = { errors: [], requiredFields: [] };

  if (celRecord) {
    // Describe Field Result
    let dfrMap;
    try {
      dfrMap = JSON.parse(celRecord[`CT_PE__Describe_Field_Results_JSON__c`]);
      //console.log("dfrMap", dfrMap);
    } catch (e) {
      console.warn(e);
      result.errors.push("Describe Field Results");
    }
    result.orgInfo = dfrMap?.UserInfo;
    if (dfrMap?.miscellaneous?.themeColor) {
      result.themeColors = dfrMap.miscellaneous.themeColor;
    }
    result.dfrMap = dfrMap;

    // New Record Layout
    let recordLayout;
    try {
      recordLayout = JSON.parse(celRecord[`CT_PE__New_Records_JSON__c`]);
    } catch (e) {
      console.warn(e);
      result.errors.push("New Records");
    };
    if (recordLayout) {
      Object.values(recordLayout)?.forEach(layout => {
        layout.inputFields.forEach(inputConfig => {
          applySchemaData(inputConfig, dfrMap?.[layout.objectName]?.[inputConfig.targetField]);
          if (inputConfig.attributes.newRecordLayout) {
            inputConfig.attributes.newRecordLayout = recordLayout[inputConfig.attributes.newRecordLayout];
          }
        });
      });
    };

    const recordLayoutsJSON = JSON.stringify(recordLayout);

    // Log Case Config
    const newSections = [];
    let logCaseConfig;
    try {
      logCaseConfig = JSON.parse(celRecord[`CT_PE__Log_Case_JSON__c`]);
      logCaseConfig.sections?.forEach(section => {
        section.inputFields.forEach(inputConfig => {
          parseSourceField(inputConfig);
        });
      });
    } catch (e) {
      console.warn(e);
      result.errors.push("Log Case");
    };
    if ((logCaseConfig?.forMeeting && logType === 'meeting') || (logCaseConfig?.forEmail && logType === 'email')) {
      // Check for schema override
      if (dfrMap?.[logCaseConfig?.targetObject]) {
        logCaseConfig.sections?.forEach(section => {
          section.inputFields.forEach(inputConfig => {
            applySchemaData(inputConfig, dfrMap[logCaseConfig.targetObject][inputConfig.targetField]);
          });
        });
      };
      result.requiredFields = (checkForRequiredFields(logCaseConfig?.sections, result.requiredFields, 'case'));
      result.caseConfig = logCaseConfig;
    };

    // Log Screen Config
    let parsedSections;
    try {
      if (logType === 'meeting') {
        const parsedLayout = JSON.parse(celRecord[`CT_PE__Log_Meeting_JSON__c`]);
        parsedSections = parsedLayout.sections;
        result.externalIdField = parsedLayout.externalIdField;
      } else {
        const parsedLayout = JSON.parse(celRecord[`CT_PE__Log_Email_JSON__c`]);
        parsedSections = parsedLayout.sections;
        result.externalIdField = parsedLayout.externalIdField;
        result.selectAttachmentByDefault = parsedLayout.selectAttachmentByDefault;
      };
    } catch (e) {
      console.warn(e);
      result.errors.push("Log Email / Meeting");
    };
    parsedSections?.forEach(sectionConfig => {
      sectionConfig.inputFields.forEach(inputConfig => {
        parseSourceField(inputConfig);
        applySchemaData(inputConfig, dfrMap?.[inputConfig.targetObject]?.[inputConfig.targetField], interactionRecordTypeId);
      });
      newSections.push(sectionConfig);
    });

    result.sourceFieldMap = populateFieldMap(recordLayoutsJSON, parsedSections);
    result.caseSourceMap = populateFieldMap(recordLayoutsJSON, logCaseConfig?.sections);
    //console.log("getConfigData", newSections);
    result.requiredFields = (checkForRequiredFields(newSections, result.requiredFields, logType));
    result.sections = newSections;

    // Related List Config
    let relatedListConfigsData;
    try {
      relatedListConfigsData = JSON.parse(celRecord[`CT_PE__Related_Lists_JSON__c`]);
    } catch (e) {
      console.warn(e);
      result.errors.push("Related Lists");
    }
    relatedListConfigsData?.forEach(relConfig => {
      if (typeof relConfig.newRecordLayout === "string") {
        // must decouple these newRecordLayout objects
        relConfig.newRecordLayout = JSON.parse(recordLayoutsJSON)[relConfig.newRecordLayout];
      }
    });
    result.relatedListConfigs = [];
    relatedListConfigsData?.forEach(config => {
      if (config.id === "attachments") {
        result.attachmentsConfig = config;
      } else if ((logType === "email" && config.forEmail) || (logType === "meeting" && config.forMeeting)) {
        if (config.objectName === contactObject) {
          config.isContactObject = true;
          if (config.internal) {
            config.recordTypeAPINames = sfAppSettings.internal;
          } else {
            config.recordTypeAPINames = sfAppSettings.external;
          }
        }
        result.relatedListConfigs.push(config);
      }
    });

    if (celRecord[`CT_PE__Dropdown_Menu_JSON__c`]) {
      let quickAddMenu;
      try {
        quickAddMenu = JSON.parse(celRecord[`CT_PE__Dropdown_Menu_JSON__c`]);
      } catch (e) {
        console.warn(e);
        result.errors.push("Dropdown Menu");
      }
      quickAddMenu?.forEach(menu => {
        menu.menuItems?.forEach(item => {
          // must decouple these newRecordLayout objects
          if (typeof item.newRecordLayout === "string") {
            item.newRecordLayout = JSON.parse(recordLayoutsJSON)[item.newRecordLayout];
          }
          // This initialization of type is to support older configurations that does not have type
          if (!item.type) {
            if (!!item.url) {
              item.type = "url";
            } else {
              item.type = "quickAdd";
            }
          }
        });
      })
      result.quickAddMenu = quickAddMenu;
    }
    // console.log("SUCCESS: Get Layout Configs");
    //buildMessageToSendToContentScript();
  };
  // console.log('parsed result', result);
  return result;
};

/**
 * @summary ...
 * @function
 * @memberof configFunctions
 * @description ...
 * @param {object} detail - ...
 * @param {array} sections - ...
*/
const processDependentInputs = (detail, sections) => {
  let hasChanges = false;
  const newSections = [...sections];
  newSections.forEach(sectionConfig => {
    sectionConfig.inputFields.forEach(inputConfig => {
      // Process Currency
      if (detail.targetField === 'CurrencyIsoCode') {
        if (inputConfig.type === 'currency') {
          if (inputConfig.currencyCode !== detail.value) {
            inputConfig.currencyCode = detail.value;
            hasChanges = true;
          }
        }
      }
      // Process Picklist
      if (inputConfig.type === 'picklist') {
        // Process RecordType
        if (detail.targetField === 'RecordTypeId') {
          if (inputConfig.recordTypeId !== detail.value) {
            inputConfig.recordTypeId = detail.value;
            hasChanges = true;
          }
        }
        // Process child picklist
        if (detail.targetField === inputConfig.controlField) {
          if (detail.multiple) {
            if (detail.value?.length > 0) {
              inputConfig.controlValues = detail.value.map(o => { return o.value });
            } else {
              inputConfig.controlValues = null;
            }
          } else {
            inputConfig.controlValues = [detail.value];
          }
          hasChanges = true;
        }
      }
    });
  });
  return hasChanges ? newSections : null;
};

/**
 * @summary ...
 * @function
 * @memberof configFunctions
 * @description ...
 * @param {object} data - ...
*/
const retrieveUserSpecificConfig = async (data) => {
  const { appSettings, locationIds, salesforceUser } = data || {}
  const soqlString = `SELECT+Id,CT_PE__Outlook_Configurations__c,SetupOwnerId`
    + `+FROM+CT_UI__ApplicationConfiguration__c+WHERE+SetupOwnerId+IN+('${locationIds.join("','")}')`;
  const url = `${constants.REACT_SERVER_URL}/api/v2/query/soql/${salesforceUser?.account}/${salesforceUser?.instance}/${salesforceUser?.accessToken}/${salesforceUser?.refreshToken}?q=${soqlString}`;
  try {
    const response = await axios.get(url, options);
    const appConfigMap = {};
    response?.data.records?.forEach(record => {
      appConfigMap[record.SetupOwnerId] = record[`CT_PE__Outlook_Configurations__c`];
    });
    if (appConfigMap[salesforceUser.id]) {
      // If user has their own config
      appSettings.chromeExtName = appConfigMap[salesforceUser.id].trim();
    } else if (appConfigMap[salesforceUser.org]) {
      // Otherwise, use org default config
      appSettings.chromeExtName = appConfigMap[salesforceUser.org].trim();
    }
    //console.log(appSettings);
    return appSettings;
  } catch (e) {
    console.log("Failed to retrieve CT_UI__ApplicationConfiguration__c from current user, profile, and org");
    console.warn("Error getting Application Configuration", e);
    return appSettings;
  };
};

export { getSalesforceAppSettings, getSalesforceAppSettingsLegacy, processChromeExtLayoutConfig, processDependentInputs };
