/**
 * @fileOverview creates a custom svg icon
 * @module components/CustomSvg
*/
import iconsMap from '../../utils/maps/iconsMap';

/**
 * @summary creates a custom icon
 * @description create custom icon component with proper fill color from salesforce config
 * @function
 * @memberof module:components/CustomSvg
 * @param {string} iconName - name of icon to be used in iconMap built from salesforce config
 * @param {string} color - icon fill color with default of "#37474f"
 * @returns {component}
*/
const ColoredSvg = ({ iconName, color }) => {
  // console.log('from colored svg', iconName, color);
  const Icon = iconsMap[iconName];
  if (!Icon) { return null; };

  return (
    <Icon fill={color || "#838b9b"} height="19px" />
  );
};

export default ColoredSvg;
