/* eslint-disable no-undef */
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { PageContext } from '../context/context';
import { constants } from '../utils/constants';
import { Button } from "@mui/material";
import LoginGoogle from '../components/LoginItem/LoginGoogle';
import LoginSalesforce from '../components/LoginItem/LoginSalesforce';
import ToastMessage from '../components/ToastMessage/ToastMessage';

import styles from './templates.module.css';


/**
 * @summary Sign In Template
 * @interface SignInTemplate
 * @description Screen for logging into salesforce and other sign ins where applicable. this is the default screen if no user is signed in
*/
const SignInTemplate = () => {
  const { googleAuthUser, isConnected, logError, msAuthUser, salesforceUser, saveResultToast, setIsLoginScreenOpen, setSaveResultToast, themeColors } = useContext(PageContext);
  const [hasDomain, setHasDomain] = useState(null);
  const [loginList, setLoginList] = useState();
  const [isOutlookIOS, setIsOutlookIOS] = useState(false);

  useEffect(() => {
    if (!salesforceUser?.account) {
      if (googleAuthUser) {
        const domain = googleAuthUser?.email?.split('@')?.[1];
        setHasDomain(domain ? domain : '');
      };
      if (msAuthUser) {
        const domain = msAuthUser?.emailAddress ? msAuthUser?.emailAddress?.split('@')?.[1] : msAuthUser?.email?.split('@')?.[1];
        setHasDomain(domain ? domain : '');
      };
    } else {
      setHasDomain(`${salesforceUser?.account}.com`);
    };
  }, [googleAuthUser, msAuthUser]);

  useEffect(() => {
    if (hasDomain && hasDomain !== '') {
      // Do not autofetch orgs if generic domain is present
      if (hasDomain !== 'gmail.com' && hasDomain !== 'outlook.com' && hasDomain !== 'cloudtheoryinc.com') {
        fetchLoginList();
      } else {
        setLoginList([
          { label: '' }
        ]);
      };
    };
  }, [hasDomain]);


  useEffect(() => {
    if (constants.EMAIL_CLIENT === 'outlook') {
      setIsOutlookIOS(Office.context?.diagnostics?.platform === "iOS");
    };
  }, []);

  // fetch login list on load or by refresh button press
  const fetchLoginList = async () => {
    try {
      const response = await axios.get(`${constants.REACT_SERVER_URL}/api/v2/auth/loginlist/${hasDomain}`);
      setLoginList(response.data);
    } catch (error) {
      logError({
        functionName: 'fetchLoginList',
        error: error,
        additionalNotes: '',
        displayedToUser: false,
        level: 'error'
      });
      console.log('error fetching login list', error, hasDomain);
      setSaveResultToast(
        <ToastMessage
          fullWidth
          message={'Could not find the configuration for this environment.  Please contact an administrator.'}
          severity={'error'}
          title={'We ran into a problem'}
          type={'alert'} />
      );
    };
  };

  return (
    <>
      {/* <MarketingBanner /> */}
      { saveResultToast }

      { constants.EMAIL_CLIENT !== 'outlook' &&
        <LoginGoogle />
      }

      <LoginSalesforce domain={hasDomain} instance={loginList} isOutlookIOS={isOutlookIOS} />

      { isConnected && !saveResultToast &&
        <Button className={styles.closeSignInButton} sx={{color:themeColors.primary, borderColor:themeColors.primary, '&:hover': {
          borderColor:themeColors.primary
          }}} variant="outlined" size={'medium'}
          onClick={() => setIsLoginScreenOpen(false)}>
          Close Login Screen
        </Button>
      }
    </>
  );
};

export default SignInTemplate;
