/**
 * @fileOverview used by renders to import correct input component and map it to template
 * @namespace componentsMap
*/
import CustomTable from "../../components/Inputs/CustomTable";
import InputAutocomplete from "../../components/Inputs/InputAutocomplete";
import InputCheckbox from '../../components/Inputs/InputCheckbox';
import InputDateTime from '../../components/Inputs/InputDateTime';
import InputEmail from "../../components/Inputs/InputEmail";
import InputLookup from "../../components/Inputs/InputLookup";
import InputNumber from '../../components/Inputs/InputNumber';
import InputPicklist from "../../components/Inputs/InputPicklist";
import InputRichText from '../../components/Inputs/InputRichText';
import InputText from '../../components/Inputs/InputText';
import InputTextArea from "../../components/Inputs/InputTextArea";


// List of input components
export const componentsMap = {
  autocomplete: InputAutocomplete,
  checkbox: InputCheckbox,
  currency: InputNumber,
  email: InputEmail,
  date: InputDateTime,
  datetime: InputDateTime,
  lookup: InputLookup,
  number: InputNumber,
  percent: InputNumber,
  picklist: InputPicklist,
  richtext: InputRichText,
  table: CustomTable,
  text: InputText,
  textarea: InputTextArea,
  time: InputDateTime
};
