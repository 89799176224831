/* eslint-disable no-undef */
/**
 * @fileOverview functions used throughout the app
 * @namespace helperFunctions
 * @requires js-base64
 * @requires axios
 * @requires dayjs
*/
import { Base64 } from 'js-base64';
import { constants } from './constants';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * @summary add category label to users outlook
 * @function
 * @memberof helperFunctions
 * @description uses salesforce org name to add a category label to recently saved email for outlook
 * @param {string} label - name of current salesforce org
*/
const addCategoryToOfficeItem = (label) => {
  Office.context.mailbox.item.categories.addAsync([label], function (asyncResult) {
    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
      // console.log('Successfully added categories');
    } else {
      console.log('categories.addAsync call failed with error: ', asyncResult.error.message, label);
    };
  });
};

/**
 * @summary creates user avatar background color
 * @function
 * @memberof helperFunctions
 * @description uses custom color from config to build out user avatar with a falback of salesforce name turned into a hex code color
 * @param {string} color - custom color coming from salesforce
 * @param {string} name - name of current salesforce user
*/
const avatarBackground = (color, name) => {
	return {
		bgcolor: color || stringToColor(name),
		width: "24px",
		height: "24px",
		lineHeight: 'normal',
		fontSize: "0.8rem"
	};
};

/**
 * @summary convert base64 to blob
 * @function
 * @memberof helperFunctions
 * @description creates a blob from base 64 data too be used in .eml file
 * @param {base64Data} base64Data - base 64 data
*/
const base64toBlob = (base64Data) => {
  return new Promise(function(resolve) {
    let contentType = '';
    const sliceSize = 1024;
    let byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      };
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    };
    resolve(new Blob(byteArrays, { type: contentType }));
  });
};

/**
 * @summary parse body data coming from google api
 * @function
 * @memberof helperFunctions
 * @description takes in body data array coming from google api call and transforms it to readable data
 * @param {array} raw - list containing body data coming from gogole
 * @param {string} mimeType - mime type of body data
*/
const buildEmailBody = (raw, mimeType) => {
  let parts = raw;
  let encodedData;
  let dataFound;
  while (!dataFound) {
    if (parts) {
      let tempParts;
      dataFound = parts.some(part => {
        if (!part.filename || part.filename === '') {
          if (part.mimeType === mimeType) {
            encodedData = part.body.data;
            tempParts = null;
            return true;
          } else {
            tempParts = part.parts;
          }
        }
        return false;
      });
      parts = tempParts;
    } else {
      dataFound = true;
    }
  }
  return restructureHTML(Base64.decode(getBase64FromBase64Url(encodedData)));
};

/**
 * @summary builds a list of emails
 * @function
 * @memberof helperFunctions
 * @description builds out email list using to,from,cc and bcc data coming from either outlook or gmail
 * @param {array} raw - could also be a string - contains email list data
*/
const buildEmailList = (raw) => {
  if (!raw) return [];
  let emailList = new Set([]);
  if (constants.EMAIL_CLIENT === 'outlook') {
    if (Array.isArray(raw)) {
      raw.forEach(item => {
        emailList.add(item.emailAddress)
      });
    } else {
      emailList.add(raw.emailAddress)
    }
    return [...emailList];
  } else {
    raw = raw.split(',');
    const reg = /(?<=<)(.*\n?)(?=>)/;
    raw.forEach(item => {
      const isMatch = item.trim().match(reg)?.flat(Infinity);
      isMatch ? emailList.add(isMatch) : emailList.add(item.trim());
    });
    const flatEmailList = [...emailList]?.flat(Infinity);
    const emailListSet = new Set(flatEmailList);
    return [...emailListSet];
  };
};

/**
 * @summary build soap querry
 * @function
 * @memberof helperFunctions
 * @description creates a soap query to be used to get mime file from email - used to create .eml file out of email
 * @param {string} itemId - outlook item id
*/
const buildMIMEContentSOAPMessage = (itemId) => {
  return (
    `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">
      <soap:Header>
        <RequestServerVersion Version="Exchange2013" xmlns="http://schemas.microsoft.com/exchange/services/2006/types" soap:mustUnderstand="0" />
      </soap:Header>
      <soap:Body>
        <GetItem xmlns="http://schemas.microsoft.com/exchange/services/2006/messages">
          <ItemShape>
            <t:BaseShape>Default</t:BaseShape>
            <t:IncludeMimeContent>true</t:IncludeMimeContent>
          </ItemShape>
          <ItemIds><t:ItemId Id="${itemId}"/></ItemIds>
        </GetItem>
      </soap:Body>
    </soap:Envelope>`
  );
};

/**
 * @summary capitalizes a string
 * @function
 * @memberof helperFunctions
 * @description function to auto return capitalized text from an input string
 * @todo consider removing this and creating a captilization class in css
 * @param {string} str - string of characters to be capitalized
*/
const capitalize = (str) => {
  return `${str[0].toUpperCase()}${str.slice(1)}`;
};

/**
 * @summary pretest for missing token
 * @function
 * @memberof helperFunctions
 * @description pretest for missing token
 * @param {string} label - name of current salesforce org
 * @param {string} token - gmail auth token
 * @param {string} color - color for label
*/
const checkForGmailLabel = async (label, token, color) => {
  if (!token) {
    token = await chrome.identity.getAuthToken({ interactive: false }, function (freshToken) {
      if (chrome.runtime.lastError) {
        return;
      } else {
        console.log('token here, get your token here', freshToken);
        return checkForGmailLabelRequest(label, freshToken, color);
      };
    })
  } else {
    return checkForGmailLabelRequest(label, token, color);
  };
};

/**
 * @summary add category label to users gmail
 * @function
 * @memberof helperFunctions
 * @description adds current salesforce org category label to users gmail
 * @param {string} label - name of current salesforce org
 * @param {string} token - gmail auth token
 * @param {string} color - color for label
*/
const checkForGmailLabelRequest = async (label, token, color) => {
  const headers = {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  };
  const url = `${constants.REACT_ATTACHMENT_ENDPOINT}/me/labels`;

  try {
    const response = await axios.get(url, headers);
    const doesLabelAlreadyExist = findMatchingLabels(response?.data?.labels, label);

    if (!doesLabelAlreadyExist) {
      createSalesforceGmailCategory(label, url, headers, color);
    } else {

      return doesLabelAlreadyExist;
    };
  } catch (error) {

    console.log('token', token);
  };
};

/**
 * @summary base64 to id
 * @function
 * @memberof helperFunctions
 * @description takes in a base 64 url from google and transforms into a useable id
 * @param {string} url - baase 64 url
*/
const chromeDecodeUrl = (url) => {
  const baseId = Base64.decode(url);
  const id = baseId.split(' ')[0];
  return id;
};

/**
 * @summary builds sql query
 * @function
 * @memberof helperFunctions
 * @description completes the SQL clause in order to query for appropriate fields in salesforce
 * @param {string} ID - baase 64 url
 * @param {object} user - salesforce user data
 * @param {string} type - type of interaction
*/
const createExternalIdSQLQuery = (ID, user, type, isCase) => {
  let SQLclause = `CT_PE__Exchange_Item_Id_255__c='${ID}' OR CT_PE__Exchange_Thread_Id_255__c='${ID}' OR CT_PE__Exchange_Internet_Message_Id_255__c='${ID}'`;
  if (type === 'email') {
    if (constants.EMAIL_CLIENT === 'outlook' && user.rivaEnabled && !isCase) {
      SQLclause += ` OR CT_PE__Exchange_Shared_Object_Id__c='${ID}'`;
    };
    if (constants.EMAIL_CLIENT === 'outlook' && user.decoratorEnabled && ID.length >= 15 && ID.length <= 18) {
      SQLclause += ` OR Id='${ID}'`;
    };
    return SQLclause;
  } else {
    SQLclause = `CT_PE__Exchange_iCalUID_255__c='${ID}' OR CT_PE__Exchange_Item_Id_255__c='${ID}' OR CT_PE__Exchange_Thread_Id_255__c='${ID}'`;
    if (constants.EMAIL_CLIENT !== 'outlook') {
      const parseId = ID.replace('@google.com', '');
      SQLclause += ` OR CT_PE__Exchange_Internet_Message_Id_255__c='${ID}'`;
      SQLclause += ` OR CT_PE__Exchange_Internet_Message_Id_255__c='${parseId}'`;
    };
    return SQLclause;
  };
};

/**
 * @summary add category label to users outlook
 * @function
 * @memberof helperFunctions
 * @description adds current salesforce org category label to users outlook
 * @param {string} label - name of current salesforce org
 * @param {string} color - color for label
*/
const createSalesforceCategory = (label, color) => {
  return new Promise(function (resolve) {
    const salesforceCategory = [{ displayName: label, color: color ? color : Office.MailboxEnums.CategoryColor.Preset0 }];
    try {
      Office.context.mailbox.masterCategories.addAsync(salesforceCategory, function (asyncResult) {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          // console.log('Successfully added Salesforce category to master list');
          resolve('success');
        } else {
          if (!asyncResult.error.message === 'One of the categories provided is already in the master category list.') {
            console.log('masterCategories.addAsync call failed with error: ', asyncResult.error.message);
            resolve('fail');
          };
          resolve('success');
        };
      });
    } catch (error) {
      console.log('error setting category', error);
      resolve('fail');
    };
  });
};

/**
 * @summary add category label to users gmail
 * @function
 * @memberof helperFunctions
 * @description adds current salesforce org category label to users gmail
 * @param {string} label - name of current salesforce org
 * @param {string} label - url to send rest request
 * @param {object} headers - rest headers to send to gmail with auth token
 * @param {string} color - color for label
*/
const createSalesforceGmailCategory = async (label, url, headers, color) => {
  try {
    if (!isHexColor(color)) color = '#cc3a21';
    const labelData = {
      name: label,
      color: {'backgroundColor': color, 'textColor': '#ffffff'}
    };

    const response = await axios.post(url, labelData, headers);
    console.log('create label response', response);
  } catch (error) {
    console.warn('error creating gmail label', error);
  };
};

/**
 * @summary validates HTML
 * @function
 * @memberof helperFunctions
 * @description takes in HTML as a string and uses DOMParser to ensure its validity
 * @param {string} htmlString - HTML string to be decoded
*/
const decodeHTMLString = (htmlString) => {
  if (htmlString[0] !== '&') {
    return htmlString
  };
  const doc = new DOMParser().parseFromString(htmlString, 'text/html');
  return doc.documentElement.textContent;
};

/**
 * @summary fetches email body from outlook
 * @function
 * @memberof helperFunctions
 * @description method to retrieve email body as text or html from outlook
 * @param {string} type - either html or text
*/
const fetchEmailBody = async (type) => {
  const emailBody = await Office.context.mailbox.item.body.getAsync(
    type,
    function (result) {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        // console.log('got body', result);
        const fixedHTML = restructureHTML(result.value);
        return fixedHTML;
      } else {
        console.log('error getting body', result)
        return {}
      }
    }
  );
  return emailBody;
};

/**
 * @summary Finds a label by name within an array of labels
 * @function
 * @memberof helperFunctions
 * @description Searches through an array of label objects and returns the ID of the first label that includes the specified label name in its name property. The search is case-insensitive. If no matching label is found, returns undefined.
 * @param {Array} labels - An array of label objects to search through. Each label object should have 'name' and 'id' properties.
 * @param {string} labelName - The name of the label to search for. The function will search for this name within the 'name' property of each label object.
 * @returns {string|undefined} The ID of the matching label, or undefined if no match is found.
 */
const findMatchingLabels = (labels, labelName) => {
  // Filters the labels array to only include labels whose name includes the searchTerm
  const labelTest = labels.find(label => label.name.toLowerCase().includes(labelName?.toLowerCase()));
  return labelTest?.id;
};

/**
 * @summary creates iCalUid query for outlook
 * @function
 * @memberof helperFunctions
 * @description builds xml to be used in SOAP query to get the iCalUid from outlook
 * @param {string} itemId - requested email itemId
*/
const generateCalendarUidSoapRequest = (itemId) => {
  return (
    `<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:m="http://schemas.microsoft.com/exchange/services/2006/messages" xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
      <soap:Header><t:RequestServerVersion Version="Exchange2013" /></soap:Header>
      <soap:Body>
        <m:GetItem>
          <m:ItemShape>
            <t:BaseShape>AllProperties</t:BaseShape>
          </m:ItemShape >
          <t:AdditionalProperties>
            <t:FieldURI FieldURI="calendar:UID"/>
            <t:ExtendedFieldURI DistinguishedPropertySetId="Meeting" PropertyId="3" PropertyType="Binary" />
          </t:AdditionalProperties>
          <m:ItemIds>
            <t:ItemId Id="${itemId}" />
          </m:ItemIds>
        </m:GetItem>
      </soap:Body>
    </soap:Envelope>`
  );
};

/**
 * @summary creates xml item query for outlook
 * @function
 * @memberof helperFunctions
 * @description builds xml to be used in SOAP query to get item data from outlook - used in EWS
 * @param {string} itemId - requested email itemId
 * @param {boolean} isMeeting - true or false if item is a meeting
*/
const generateSOAPForEWS = (itemId, isMeeting) => {
  let additionalProperties = `
    <t:FieldURI FieldURI="item:Subject" />
    <t:FieldURI FieldURI="item:Body" />
  `;

  if (isMeeting) {
    additionalProperties += '<t:FieldURI FieldURI="calendar:UID" />';
    additionalProperties += '<t:FieldURI FieldURI="calendar:IsRecurring" />';
    additionalProperties += '<t:FieldURI FieldURI="calendar:IsAllDayEvent" />';
  };

  return (
    `<soap:Envelope
      xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
      xmlns:xsd="http://www.w3.org/2001/XMLSchema"
      xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types"
      xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
      <soap:Header>
        <RequestServerVersion Version="Exchange2013" xmlns="http://schemas.microsoft.com/exchange/services/2006/types" soap:mustUnderstand="0" />
      </soap:Header>
      <soap:Body>
        <GetItem xmlns="http://schemas.microsoft.com/exchange/services/2006/messages">
          <ItemShape>
            <t:BaseShape>IdOnly</t:BaseShape>
            <t:AdditionalProperties>
              ${additionalProperties}
              <t:BodyType>HTML</t:BodyType>
            </t:AdditionalProperties>
          </ItemShape>
            <ItemIds>
              <t:ItemId Id="${itemId}"/>
            </ItemIds>
        </GetItem>
      </soap:Body>
    </soap:Envelope>`
  );
};

/**
 * @summary prepares base64Url data
 * @function
 * @memberof helperFunctions
 * @description fixes base64url encoding so data can be decoded from base64 into a string - used to decode some google data
 * @param {array} base64urlData - google body data
*/
const getBase64FromBase64Url = (base64urlData) => {
  if (base64urlData) {
    let base64Data = base64urlData.replaceAll("-", "+").replaceAll("_", "/");
    const loopLength = base64Data.length % 4;
    let missingChar = '';
    for (let i = 0; i < loopLength; i++) {
      missingChar += "=";
    };
    return base64Data + '' + missingChar;
  };
  return '';
};

/**
 * @summary gets current tab information in chrome
 * @function
 * @memberof helperFunctions
 * @description uses chrome api to gather data about current tab
*/
const getCurrentTab = async () => {
  let queryOptions = { active: true, currentWindow: true, lastFocusedWindow: true };
  // `tab` will either be a `tabs.Tab` instance or `undefined`.
  let [tab] = await window['chrome'].tabs.query(queryOptions);
  return tab;
};

/**
 * @summary fetches and creats .eml file
 * @function
 * @memberof helperFunctions
 * @description fetches mime data via soap call through EWS and then decodes the base64 data into a blob with file type .eml to be used in case
 * @param {string} itemId - outlook item id
*/
const getEMLFileForCase = (itemId) => {
  let _id = ''
  if (!itemId) {
    _id = Office.context.mailbox.item.itemId;
  } else {
    _id = itemId
  };
  return (new Promise(function (resolve) {
    if (Office.context.mailbox.getCallbackTokenAsync) {
      try {
        Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, function (resultToken) {
          const soapEnvelope = buildMIMEContentSOAPMessage(_id);
          try {
            Office.context.mailbox.makeEwsRequestAsync(
              soapEnvelope, function (soapAsyncResult) {
                if (soapAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
                  const parser = new DOMParser();
                  const doc = parser.parseFromString(soapAsyncResult.value, "text/xml");
                  const soapMessage = doc.getElementsByTagName("m:ResponseCode");
                  if (!soapMessage?.[0]?.innerHTML?.includes('NoError')) {
                    return resolve({ status: 'error', message: soapMessage?.[0]?.innerHTML });
                  };
                  const values = doc.getElementsByTagName("t:MimeContent");
                  const subject = doc.getElementsByTagName("t:Subject");
                  const fileName = subject?.[0]?.textContent ? subject?.[0]?.textContent : "undefined";
                  const fileText = values?.[0]?.textContent;

                  setAttachmentEML((fileName + ".eml"), fileText).then(function (downloadInformation) {
                    // const testReturn = { caseEML: values[0].textContent, filename: subject[0].textContent + ".eml" };
                    resolve({ status: 'success', file: downloadInformation });
                  });
                } else {
                  // console.log('soapAsyncResult', soapAsyncResult)
                  resolve({ status: 'error', message: soapAsyncResult?.error?.message });
                };
              }
            );
          } catch (e) {
            console.log('failed EWS callout', e);
            resolve({ status: 'error' });
          };
        });
      } catch (e) {
        console.log('failed to get token', e);
        resolve({ status: 'error' });
      };
    };
  }));
};

/**
 * @summary fetches item data from outlook
 * @function
 * @memberof helperFunctions
 * @description uses a callback function to make async request to outlook inorder to get item data
 * @param {function} callback - function to return either an error or item info when making soap call
*/
const getEventOutlookUid = (callback, _item) => {
  if (typeof Office.context.mailbox.item.getItemIdAsync === 'function') { // is Organizer
    Office.context.mailbox.item.getItemIdAsync(function (result) {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        if (_item) return _item.itemId = result?.value;
        callback(null, result.value);
      } else {
        Office.context.mailbox.item.saveAsync(function(result) {
          if (result.status === Office.AsyncResultStatus.Succeeded) {
            if (_item) return _item.itemId = result?.value;
            callback(null, result.value);
          } else {
            const message = `EventOutlookUid unavailable: ${result.error.message}. Probably just a new event`;
            console.warn(message);
            if (_item) return _item.itemId = '';
            callback(message, null);
          };
        });
      };
    })
  } else if (Office.context.mailbox?.item?.itemId) { // is Attendee
    if (_item) return _item.itemId = Office.context?.mailbox?.item?.itemId;
    callback(null, Office.context.mailbox?.item?.itemId);
  } else {
    if (_item) return _item.itemId = _item.itemId;
    callback(Error('Neither Office.context.mailbox.item.getItemIdAsync nor Office.context.mailbox.item.itemId could get Outlook Item UID'));
  };
};

/**
 * @summary fetches item data from outlook
 * @function
 * @memberof helperFunctions
 * @description uses a callback function to make async request to outlook in order to get item data then parse it from xml response
 * @param {function} callback - function to return either an error or item info when making soap call
*/
const getExtendedIds = (callback) => {
  getEventOutlookUid((err, eventOutlookUid) => {
    if (err) {
      // console.error('Error fetching Outlook UID ' + err.message);
      callback(err, null);
    } else {
      const soapRequest = generateCalendarUidSoapRequest(eventOutlookUid);
      if (soapRequest) {
        Office.context.mailbox?.makeEwsRequestAsync(soapRequest, function (result) {
          if (result.status === Office.AsyncResultStatus?.Succeeded) {
            const msUID = result.value.substring(result.value.lastIndexOf('<t:UID>') + ('<t:UID>').length, result.value.lastIndexOf('</t:UID>'));
            callback(null, msUID);
          } else {
            // potential fail caused by api no supported in shared folders
            // console.log('result error', result);
            callback(result.error, null);
          };
        })
      } else {
        callback(Error('Invalid XML request'));
      };
    };
  })
};

/**
 * @summary auto truncates a field
 * @function
 * @memberof helperFunctions
 * @description if config has an autotruncate option turned on and the text is larger tham field limits - truncate the text automaticaly
 * @param {string} textvalue - value of the text field
 * @param {object} config - field config object
*/
const getTruncatedText = (textValue, config) => {
  if (config?.autoTruncate && typeof config?.attributes?.maxLength === "number" && textValue?.length > config.attributes.maxLength) {
    return textValue.substring(0, config.attributes.maxLength);
  };
  return textValue;
};

/**
 * @summary validates hex colors
 * @function
 * @memberof helperFunctions
 * @description takes in a string and tests it with regex to confirm this color is a valid hex color
 * @param {string} color - color to test if is valid hexl
*/
const isHexColor = (color) => {
  const reg = /^#([0-9a-f]{3}){1,2}$/i;
  return reg.test(color);
};

/**
 * @summary validates office api support
 * @function
 * @memberof helperFunctions
 * @description checks if office version will support the api call
 * @param {string} officeVersion - office version ie: "1.5"
*/
const isOfficeSetSupported = (officeVersion) => {
  return Office?.context?.requirements?.isSetSupported('Mailbox', officeVersion);
};

/**
 * @summary truncates strings
 * @function
 * @memberof helperFunctions
 * @description truncates a text string to a desired limit - used to make sure calls dont fail due to a string too loarge error
 * @param {string} string - string to limit
 * @param {number} limit - number of charachters to limit to, uses fallback set in constants
*/
const limitCharacters = (string, limit = constants.ENTITY_RECOGNITION_CHARACTER_LIMIT) => {
  return string?.substring(0, limit)
};

/**
 * @summary matches values to fields
 * @function
 * @memberof helperFunctions
 * @description matches field values from api calls to fields and types set in salesforce config
 * @param {any} itemValue - value of item from api call
 * @param {string} itemType - the type of field from salesforce config
 * @param {string} sourceField - source field from salesforce config
*/
const parseGoogleItemData = (itemValue, itemType, sourceField) => {
  let result;
  //Using try catch here because I don't think we should abort the entire process due to unknown parsing error
  try {
    if (sourceField === 'parts') {
      const mimeType = itemType === 'richtext' ? 'text/html' : 'text/plain';
      result = buildEmailBody(itemValue, mimeType);
    } else if (itemType === 'email') {
      result = buildEmailList(itemValue);
    } else if (itemType === 'text') {
      //remove emoji
      result = itemValue?.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');
    } else {
      result = itemValue;
    }
  } catch (error) {
    // TODO should there be error handling here?
    // console.warn('error parseing google email data', error);
  }
  // console.log('result', result);
  return result;
};

/**
 * @summary matches values to fields
 * @function
 * @memberof helperFunctions
 * @description parse data from calendar api call to match the required field types coming from salesforce
 * @param {any} itemValue - value of item from api call
 * @param {string} itemType - the type of field from salesforce config
*/
const parseGoogleItemData_Meeting = (itemValue, itemType) => {
  let result;
  // console.log('itemValue, itemType', itemValue, itemType)
  try {
    if (itemType === 'datetime' || itemType === 'time') {
      if (itemValue.date) {
        let dtVal = dayjs(itemValue.date);
        result = dtVal.utc().toISOString();
      } else if (itemValue.dateTime) {
        let dtVal = dayjs(new Date(itemValue.dateTime));
        result = dtVal.utc().toISOString();
      } else if (typeof itemValue === 'object' && !itemValue.getAsync) {
        let dtVal = dayjs(new Date(itemValue));
        result = dtVal.utc().toISOString();
      } else if (!itemValue.getAsync) {
        let dtVal = dayjs(new Date(itemValue));
        result = dtVal.utc().toISOString();
      };
    } else if (itemType === 'text' && typeof itemValue === 'string') {
      //remove emoji
      result = itemValue?.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');
    } else if (itemType === 'text' && typeof itemValue === 'object') {
      result = '';
    } else {
      result = itemValue;
    };
  } catch (error) {
    // console.log(itemType, itemValue);
    console.warn('error parsing google event data', error);
  };
  // console.log('result', result)
  return result;
};

/**
 * @summary parse a name into first and last name
 * @function
 * @memberof helperFunctions
 * @description parse a name into first and last name
 * @param {string} nameStr) - the type of field from salesforce config
*/
const parseNameString = (nameStr) => {
  let firstName = '';
  let lastName = '';

  if (nameStr.includes(',')) {
    // Assume last name, first name format if comma is present
    const nameParts = nameStr.split(',').map(part => part.trim());
    lastName = nameParts[0] || '';
    firstName = nameParts[1] || '';
  } else {
    // Split only into two parts: first and last name
    const nameParts = nameStr.split(/\s+/, 2);
    firstName = nameParts[0] || '';
    lastName = nameParts[1] || '';
  };

  return { firstName: firstName, lastName: lastName };
};

/**
 * @summary ask duy
 * @function
 * @memberof helperFunctions
 * @description im sure there is a purpose, just in case ask duy
*/
const parseSalesforceRecordData = (itemValue, itemType) => {
  let result;
  try {
    // Left tempty to open for handling of special field types
    result = itemValue;
  } catch (error) {
    console.warn('error parsing interaction data', itemValue, itemType, error);
  };
  return result;
};

/**
 * @summary validates HTML
 * @function
 * @memberof helperFunctions
 * @description takes in HTML as a string and removes broken html by using dom parser to remove any extra data
 * @param {string} htmlString - HTML string to be decoded
*/
const restructureHTML = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const headElement = doc.querySelector('head');
  // const bodyElement = doc.querySelector('body');

  // Move title, meta, link, and style elements to the head
  const elementsToMove = ['title', "meta[http-equiv='Content-Type']", 'link', 'style'];
  elementsToMove.forEach(selector => {
    const originalElements = doc.querySelectorAll(selector);
    originalElements.forEach(element => {
      headElement.appendChild(element);
    });
  });

  // Serialize the modified document back to HTML
  const regexString = '/.+?(?=[a-z]|[A-Z]|[0-9]|( -!))/s';
  const modifiedHtmlString = doc.documentElement.outerHTML;
  try {
    return modifiedHtmlString.replace(regexString, '').trim().replace(/<style([\s\S]+?)<\/style>/gm, '').replace(/background='.*?'/gm, '');
  } catch (error) {
    return modifiedHtmlString;
  };
};

/**
 * @summary checks for record match in salesforce via subject & datetime
 * @function
 * @memberof helperFunctions
 * @description queries for a match against email to org based off subject and datetime - if true returns record for match
 * @param {string} url - url for rest call
 * @param {string} soqlString - sql query sttring
 * @param {string} options - http header options
*/
const runFuzzyMatch = async (url, soqlString, options, type) => {
  const item = Office.context.mailbox.item;
  try {
    const beginingClause = soqlString.split('WHERE')[0];
    const formattedDate = dayjs(item?.dateTimeCreated)?.utc()?.toISOString();
    const formattedStart = dayjs(item?.start)?.utc()?.toISOString();
    const formattedEnd = dayjs(item?.end)?.utc()?.toISOString();
    let endingClause = ` WHERE Name='${item?.subject}' AND CT_PE__Email_Sent_Date_Time__c=${formattedDate}`;
    if (type === 'meeting' || item?.itemType === 'appointment') {
      endingClause = ` WHERE Name='${item?.subject}' AND CT_PE__Start_Date_Time__c=${formattedStart} AND CT_PE__End_Date_Time__c=${formattedEnd}`;
    }
    const fuzzyMatchQuery = beginingClause + endingClause;
    const response = await axios.post(url, { data: JSON.stringify(fuzzyMatchQuery) }, options);
    const result = await response.data;
    if (result?.records?.length > 0) {
      return result;
    } else {
      return false;
    };
  } catch (error) {
    // console.log("unable to perform fuzzy match query", error);
    return false;
  };
};

/**
 * @summary crate eml file object
 * @function
 * @memberof helperFunctions
 * @description transforms the base 64 data of the email text into a blob .eml file
 * @param {string} filename - name to be used for .eml file
 * @param {base64Data} text - the text of email in base64 format
*/
const setAttachmentEML = (filename, text) => {
  if (filename && text) {
    return new Promise(function (resolve) {
      base64toBlob(text).then(function(downloadblob) {
        const returnObj = { caseEML: text, filename: filename, caseEMLBlob: downloadblob };
        resolve(returnObj);
      });
    });
  } else {
    return null;
  }
};

/**
 * @summary parse user display name to be used in avatar
 * @function
 * @memberof helperFunctions
 * @description parse user display name to be used in avatar - this is to display initals from salesforce user or microsoft/google user
 * @param {string} name - salesforce user display name
 * @param {string} user - google/microsoft display name
*/
const stringAvatar = (name, user) => {
  if (!name) return user;
  return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
};

/**
 * @summary returns hex color from display name
 * @function
 * @memberof helperFunctions
 * @description takes in display name and gives unique hex color
 * @param {string} name - salesforce user display name
*/
const stringToColor = (name) => {
  if (!name) return "#91db8b";
  let hash = 0;
  let i;
  for (i = 0; i < name.length; i += 1) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

/**
 * @summary adds color options to app theme
 * @function
 * @memberof helperFunctions
 * @description validates colors and adds them to the project depending on what gets sent from salesforce config
 * @param {object} defaultColors - default colors used in app {primar, seconday, tertiary}
 * @param {any} newColors - could be a string or object depending on salesforce config - containg branding colors
*/
const updateThemeColor = (defaultColors, newColors) => {
  if (typeof newColors === "string" && isHexColor(newColors)) {
    return {
      primary: newColors,
      secondary: "",
      tertiary: "",
    };
  } else if (typeof newColors === "object" && newColors !== null) {
    for (const key in newColors) {
      if (newColors[key] !== "" && isHexColor(newColors[key])) {
        defaultColors[key] = newColors[key];
      };
    };
    return defaultColors;
  };
};

/**
 * @summary basic email validator
 * @function
 * @memberof helperFunctions
 * @description validates with regex that an email daomin is at least formated correctly
 * @param {string} domain - the email domain (investorflow.com etc)
*/
const validateEmail = (domain) => {
  const email = `example@${domain}`
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export {
  avatarBackground, addCategoryToOfficeItem, buildEmailBody, capitalize, checkForGmailLabel, chromeDecodeUrl, createExternalIdSQLQuery, createSalesforceCategory,
  decodeHTMLString, fetchEmailBody, getCurrentTab, getBase64FromBase64Url, getEMLFileForCase, getExtendedIds, getEventOutlookUid, getTruncatedText, isOfficeSetSupported, limitCharacters,
  parseGoogleItemData, parseGoogleItemData_Meeting, parseNameString, parseSalesforceRecordData, restructureHTML, runFuzzyMatch, stringAvatar, stringToColor, updateThemeColor, validateEmail
};
