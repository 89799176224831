import { useState, useEffect } from 'react';
import styles from './IconButtons.module.css';


const IconCheckbox = (props) => {
  const { checkedIcon, uncheckedIcon, disabled, checked, name, index, onChange } = props || {};
  const [internalValue, setInternalValue] = useState(!!checked);

  useEffect(() => {
    if (checked !== internalValue) {
      setInternalValue(!!checked);
    };
  }, [checked]);

  const toggleCheckbox = (event) => {
    if (!!disabled) {
      event.preventDefault();
      return;
    };

    const newValue = !internalValue;
    setInternalValue(newValue);
    if (onChange && typeof onChange === "function") {
      onChange({
        name: name,
        index: index,
        value: newValue
      });
    };
  };


  return (
    <div className={styles.buttonContainer} data-disabled={disabled} onClick={toggleCheckbox}>
      { internalValue
        ? (checkedIcon)
        : (uncheckedIcon)
      }
    </div>
  );
};

export default IconCheckbox;
