import { useContext, useId } from 'react';
import { constants } from './utils/constants';
import { PageContext } from './context/context';
import { LoadingContext } from './context/loadingContext';
import { ThemeProvider } from '@mui/material/styles';
import { primaryTheme } from './utils/theme';
import DebugPanelTemplate from './templates/DebugPanelTemplate';
import Header from './components/Header/Header';
import IntroScreen from './templates/IntroScreen';
import LogCaseTemplate from './templates/LogCaseTemplate';
import LogInteractionTemplate from './templates/LogInteractionTemplate';
import Modal from './components/User/Modal';
import SignInTemplate from './templates/SignInTemplate';

import LoginSuccess from './templates/LoginSuccess';


/**
 * @summary Entry Page into Application
 * @namespace Pages/App
 * @description Main entry point to app wraps MUI theme around templates
 */
const App = ({isOfficeInitialized}) => {
  // console.log('app', isOfficeInitialized)
  const { isLoginScreenOpen, screenType, showLoginSuccess, showUserMenu, themeColors, userMenuAnchorEl } = useContext(PageContext);
  const { loadingToast } = useContext(LoadingContext);
  const userMenuCmpId = useId();

  return (
    !showLoginSuccess

    ? <ThemeProvider theme={primaryTheme(themeColors.primary)}>
        <Header
          showUserMenu={showUserMenu}
          userMenuCmpId={userMenuCmpId}
        />
        <main className='main'>
          <div className={constants.EMAIL_CLIENT === "outlook" ? "mainPaddingOutlook" : "mainPadding"} />

          { loadingToast }

          { showUserMenu &&
            <Modal
              anchor={userMenuAnchorEl}
              id={userMenuCmpId}
              open={showUserMenu}
            />
          }

          { screenType === "case" &&  <LogCaseTemplate /> }
          { screenType === "debug" && <DebugPanelTemplate /> }
          { screenType === "interaction" && <LogInteractionTemplate /> }
          { screenType === "intro" &&
            ( !isLoginScreenOpen
              ? <IntroScreen />
              : <div className="p-top_small">
                  <SignInTemplate />
                </div>
            )
          }
        </main>
      </ThemeProvider>
    : <LoginSuccess />
  );
};

export default App;
