/**
 * @fileOverview used by attachment component to render proper icon for email/meeting attachments
 * @namespace fileIconsMap
*/
import AiSvg from "../../assets/doctype/ai.svg";
import AttachmentSvg from "../../assets/doctype/attachment.svg";
import AudioSvg from "../../assets/doctype/audio.svg";
import BoxNotesSvg from "../../assets/doctype/box_notes.svg";
import CsvSvg from "../../assets/doctype/csv.svg";
import EpsSvg from "../../assets/doctype/eps.svg";
import ExcelSvg from "../../assets/doctype/excel.svg";
import ExeSvg from "../../assets/doctype/exe.svg";
import FlashSvg from "../../assets/doctype/flash.svg";
import FolderSvg from "../../assets/doctype/folder.svg";
import GDocSvg from "../../assets/doctype/gdoc.svg";
import GDocsSvg from "../../assets/doctype/gdocs.svg";
import GFormSvg from "../../assets/doctype/gform.svg";
import GPresSvg from "../../assets/doctype/gpres.svg";
import GSheetSvg from "../../assets/doctype/gsheet.svg";
import HtmlSvg from "../../assets/doctype/html.svg";
import ImageSvg from "../../assets/doctype/image.svg";
import KeyNoteSvg from "../../assets/doctype/keynote.svg";
import LibraryFolderSvg from "../../assets/doctype/library_folder.svg";
import LinkSvg from "../../assets/doctype/link.svg";
import MP4Svg from "../../assets/doctype/mp4.svg";
import OverLaySvg from "../../assets/doctype/overlay.svg";
import PackSvg from "../../assets/doctype/pack.svg";
import PagesSvg from "../../assets/doctype/pages.svg";
import PdfSvg from "../../assets/doctype/pdf.svg";
import PptSvg from "../../assets/doctype/ppt.svg";
import PsdSvg from "../../assets/doctype/psd.svg";
import QuipDocSvg from "../../assets/doctype/quip_doc.svg";
import QuipSheetSvg from "../../assets/doctype/quip_sheet.svg";
import QuipSlideSvg from "../../assets/doctype/quip_slide.svg";
import RtfSvg from "../../assets/doctype/rtf.svg";
import SlideSvg from "../../assets/doctype/slide.svg";
import StypiSvg from "../../assets/doctype/stypi.svg";
import TxtSvg from "../../assets/doctype/txt.svg";
import UnknownSvg from "../../assets/doctype/unknown.svg";
import VideoSvg from "../../assets/doctype/video.svg";
import VisioSvg from "../../assets/doctype/visio.svg";
import WebexSvg from "../../assets/doctype/webex.svg";
import WordSvg from "../../assets/doctype/word.svg";
import XmlSvg from "../../assets/doctype/xml.svg";
import ZipSvg from "../../assets/doctype/zip.svg";


const fileIconsMap = {
  ai: AiSvg,
  attachment: AttachmentSvg,
  audio: AudioSvg,
  box_notes: BoxNotesSvg,
  csv: CsvSvg,
  docx: WordSvg,
  eps: EpsSvg,
  excel: ExcelSvg,
  exe: ExeSvg,
  flash: FlashSvg,
  folder: FolderSvg,
  gdoc: GDocSvg,
  gdocs: GDocsSvg,
  gform: GFormSvg,
  gpres: GPresSvg,
  gsheet: GSheetSvg,
  html: HtmlSvg,
  image: ImageSvg,
  jpg: ImageSvg,
  keynote: KeyNoteSvg,
  library_folder: LibraryFolderSvg,
  link: LinkSvg,
  mp4: MP4Svg,
  overlay: OverLaySvg,
  pack: PackSvg,
  pages: PagesSvg,
  pdf: PdfSvg,
  png: ImageSvg,
  ppt: PptSvg,
  pptx: PptSvg,
  psd: PsdSvg,
  quip_doc: QuipDocSvg,
  quip_sheet: QuipSheetSvg,
  quip_slide: QuipSlideSvg,
  rtf: RtfSvg,
  slide: SlideSvg,
  stypi: StypiSvg,
  txt: TxtSvg,
  unknown: UnknownSvg,
  video: VideoSvg,
  visio: VisioSvg,
  webex: WebexSvg,
  word: WordSvg,
  xml: XmlSvg,
  zip: ZipSvg
};

export default fileIconsMap;
