import { useContext } from 'react';
import { PageContext } from '../../context/context';
import { Alert, AlertTitle, Dialog, DialogTitle } from '@mui/material';

/**
 * ToastMessage component displays messages to the user as either a toast alert or a modal dialog based on the `type` prop.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string|Object} props.message - The message content to display. Can be a string or an object with a message property.
 * @param {string} props.severity - The severity of the alert. Common values are "error", "warning", "info", "success".
 * @param {string} props.title - The title of the alert.
 * @param {string} props.type - Determines the type of display: 'alert' for Alert component, others for Dialog component.
 * @returns {JSX.Element} A conditional rendering of either an Alert or Dialog component based on the `type` prop.
 *
 * @description
 * This component is designed to provide feedback to users through different types of visual indicators:
 * - An Alert for less intrusive messages, allowing the user to close it.
 * - A Dialog for more persistent messages that require user acknowledgment to close.
 * 
 * The component uses the `setSaveResultToast` method from `PageContext` to manage the display state, which is typically 
 * used to reset the toast state to `null` after closing an alert.
 */
const ToastMessage = (props) => {
  // console.log('from toast message', props);
  let { message, severity, title, type } = props || {};
  const { setSaveResultToast } = useContext(PageContext);

  if (message?.message) message = message.message;

  return (
    <>
      { type === 'alert'
        ? <Alert
            severity={severity}
            variant="filled"
            onClose={() => setSaveResultToast(null)}
            sx={{ position: "relative", zIndex: 1 }}
            >
            <AlertTitle>{title}</AlertTitle>
            { message }
          </Alert>
        : <Dialog open
            aria-labelledby="alert-dialog-title"
            disableEscapeKeyDown
            onClose={(e) => { e.preventDefault() }}
          >
            <DialogTitle id="alert-dialog-title">
              { message }
            </DialogTitle>
          </Dialog>
      }
    </>
  );
};

export default ToastMessage;
