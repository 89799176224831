import Avatar from '@mui/material/Avatar';
import { avatarBackground, stringAvatar } from '../../utils/helperFunctions';

/**
 * @summary creates a custom user avatar
 * @description creates a custom user avatar with set color from salesforce config or random color created by user initals
 * @function
 * @memberof module:components/User
 * @param {string} id - component id
 * @param {function} onClick - function to handle onClick event
 * @param {string} themeColor - tertiary color set in salesforce config
 * @param {string} salesforceUser - name of current salesforce user
 * @param {string} user - name of current microsoft or google user
 * @returns {component}
*/
const CustomAvatar = ({ id, onClick, themeColor, salesforceUser, user }) => {
  const name = stringAvatar(salesforceUser, user);
  const theme = avatarBackground(themeColor, salesforceUser);

  return (
    <Avatar
      id={id}
      onClick={onClick}
      style={{ cursor: "pointer" }}
      sx={theme}
    >
      {name}
    </Avatar>
  );
};

export default CustomAvatar;
