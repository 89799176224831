import { useContext, useEffect, useState } from 'react';
import { PageContext } from '../../context/context';
import { TextField, InputAdornment } from "@mui/material";
/**
 * InputNumber is a controlled input component designed for numerical values, supporting enhanced functionalities
 * such as precision control, quick multiplier key actions, and adornments for currency and percentage inputs.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.config - Configuration for the number input, including attributes such as digits, precision, scale, and type (currency or percent).
 * @param {string} props.id - Unique identifier for the number input, used for accessibility and managing form control.
 * @param {string} props.label - Label text for the input field, describing the field to users.
 * @param {string} props.sectionId - Identifier for the form section that this input belongs to, used for managing state in context.
 * @param {Function} props.onChange - Callback function triggered when the input value changes. It handles data processing and storage.
 * @returns {JSX.Element} A TextField component tailored for numerical inputs with specific validation and adornments.
 *
 * @description
 * This component renders a TextField specifically tailored for numbers. It integrates several features:
 * - Precision control through configurable `digits`, `precision`, and `scale` properties.
 * - Quick actions for multipliers (billion, million, thousand) when typing 'b', 'm', or 'k' after a number.
 * - Conditional adornments based on input type (currency or percent), displaying appropriate symbols or currency codes.
 * - Real-time validation against a dynamically constructed regex pattern to enforce proper numeric format.
 *
 * It uses a combination of local state and context (from `PageContext`) to manage the value and validation status of the input.
 * The input's value is debounced to delay processing and minimize unnecessary updates, which is particularly useful for dynamic validations.
 * This component is well-suited for forms requiring precise numerical inputs with options for quick data entry adjustments.
 */
const InputNumber = (props) => {
  // console.log('from InputNumber', props)
  const { config, id, label, sectionId, onChange } = props || {};
  const { digits, precision, scale } = config?.attributes || {};
  const { handleOnChangeWithDetail, orgInfo, valueStorage } = useContext(PageContext);

  const [onchangeTimeout, setOnchangeTimeout] = useState(null); //This is needed otherwise clearTimeout wont work
  const [invalidInput, setInvalidInput] = useState(false);
  const [wholeDigits, setWholeDigits] = useState(12);
  const [decimalDigits, setDecimalDigits] = useState(6);
  // TODO test this regex in create new opportunity
  const [regexStr, setRegexStr] = useState("^\\d{0,12}(\\.\\d{0,6})?$");

  /* START Internal Value */
  const [internalValue, setInternalValue] = useState(config?.defaultValue ?? '');
  useEffect(() => {
    if (valueStorage?.[sectionId]?.[id]) {
      const newValue = valueStorage[sectionId][id].value;
      if (newValue !== internalValue) {
        setInternalValue(newValue);
      };
    };
  }, [valueStorage, sectionId, id]);
  /* END Internal Value */

  useEffect(() => {
    if (typeof digits === 'number' && digits > 0) {
      setWholeDigits(digits);
      setDecimalDigits(0);
      setRegexStr(`^\\d{0,${digits}}$`);
    } else if (typeof precision === 'number' && typeof scale === 'number') {
      setWholeDigits(precision - scale);
      setDecimalDigits(scale);
      let newRegexStr = `\\d{0,${precision - scale}}`;
      if (scale > 0) {
        newRegexStr += `(\\.\\d{0,${scale}})?`;
      };
      setRegexStr("^" + newRegexStr + "$");
    }
  }, [digits, precision, scale]);


  const handleOnChange = (e) => {
    const newValue = e.target.value;
    if (onchangeTimeout) clearTimeout(onchangeTimeout);
    setOnchangeTimeout(setTimeout(() => {
      const numberRegex = new RegExp(regexStr, "g");
      if (numberRegex.test(newValue)) {
        setInvalidInput(false);
        const detail = {
          inputId: id,
          sectionId: sectionId,
          targetField: config?.targetField,
          type: config?.type,
          value: newValue
        };
        if (typeof onChange === 'function') {
          onChange(detail);
        } else {
          handleOnChangeWithDetail(detail);
        }
      } else {
        setInvalidInput(true);
      };
    }, 500));
    setInternalValue(newValue);
  };

  const handleKeyDown = (e) => {
    if (internalValue) {
      if (e.key === "b") {
        e.target.value = (parseFloat(internalValue) * 1000000000) + "";
        handleOnChange(e);
        e.preventDefault();
      } else if (e.key === "m") {
        e.target.value = (parseFloat(internalValue) * 1000000) + "";
        handleOnChange(e);
        e.preventDefault();
      } else if (e.key === "k") {
        e.target.value = (parseFloat(internalValue) * 1000) + "";
        handleOnChange(e);
        e.preventDefault();
      }
    }
  };

  const getInputProps = () => {
    const result = {};
    if (config.type === 'currency') {
      if (config?.currencyCode) {
        result.endAdornment = (
          <InputAdornment position="end">
            <span style={{ fontSize: "0.8125rem" }}>{config.currencyCode}</span>
          </InputAdornment>
        );
      } else if (orgInfo?.defaultCurrencyCode) {
        result.endAdornment = (
          <InputAdornment position="end">
            <span style={{ fontSize: "0.8125rem" }}>{orgInfo.defaultCurrencyCode}</span>
          </InputAdornment>
        );
      } else {
        result.endAdornment = (
          <InputAdornment position="end">
            <span style={{ fontSize: "0.8125rem" }}>USD</span>
          </InputAdornment>
        );
      }
    } else if (config.type === 'percent') {
      result.endAdornment = (<InputAdornment position="end">%</InputAdornment>);
    } else {
      // error handling? default?
    };
    return result;
  };

  return (
    <TextField {...config?.attributes} type="number"
      id={id} label={label} value={internalValue}
      error={invalidInput}
      helperText={invalidInput && `Please use ${wholeDigits} digits for whole number and ${decimalDigits} digits for decimal part.`}
      InputProps={getInputProps()}
      onChange={handleOnChange}
      onKeyDown={handleKeyDown} />
  );
};

export default InputNumber;
