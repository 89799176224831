import SalesForceLogo from '../assets/images/salesforce-success.webp';


const LoginSuccess = () => {

  const getData = () => {
    const targetOrigin = `https://${location?.host}`;

    Office.onReady()
      .then(function() {
        messageClient();
    });

    const getCookie = (key) => {
      let cookie = `; ${document.cookie}`.split(`; ${key}=`)?.pop()?.split(`;`)?.shift();
      if (!cookie) {
        cookie = decodeURI(location?.search?.split('?info=')?.[1]);
      };
      // console.log('cookie', cookie);
      return cookie;
    };


    // wait 1.5 seconds before trying to send post message as fallback
    setTimeout(() => {
      const investorflow = getCookie('_investorflow');
      if (window.opener) {
        // console.log('messaging through window opener');
        window.opener.postMessage(investorflow, targetOrigin);
      } else if (window.postMessage) {
        // console.log('messaging through window');
        window.postMessage(investorflow, targetOrigin);
      } else {
        // console.log('messaging through window top');
        window.top.postMessage(investorflow, targetOrigin);
      };
    }, 1500);


    const messageClient = () => {
      const investorflow = getCookie('_investorflow');
      // console.log('messaging through office', location.host, targetOrigin);
      Office?.context?.ui?.messageParent(investorflow, { targetOrigin: targetOrigin });
    };

    // close window after 3 seconds if add in doesnt do it for the user
    setTimeout(() => {
      window.close();
    }, 3000);
  };

  // if url contains ?info= then we have user data from server, if it contains ?redirect= then we still need to redirect to server so we can log into salesforce
  if (location.search?.includes("?info=")) {
    // console.log('success');
    getData();
  } else {
    const search = location.search.split('?redirect=')[1];
    const redirection = search?.split('&')?.[0];
    // console.log(search)
    // console.log('decoced', redirection);
    // redirectUser();
    window.location.assign(decodeURIComponent(redirection));
  };

  return (
    <section className='sign-in-success'>
      <img alt="salesforce login" src={SalesForceLogo} />
      {/* <a className="btn" href="javascript:(function(F,i,r,e,b,u,g,L,I,T,E){if(F.getElementById(b))return;E=F[i+'NS']&&F.documentElement.namespaceURI;E=E?F[i+'NS'](E,'script'):F[i]('script');E[r]('id',b);E[r]('src',I+g+T);E[r](b,u);(F[e]('head')[0]||F[e]('body')[0]).appendChild(E);E=new%20Image;E[r]('src',I+L);})(document,'createElement','setAttribute','getElementsByTagName','FirebugLite','4','firebug-lite.js','releases/lite/latest/skin/xp/sprite.png','https://cdnjs.cloudflare.com/ajax/libs/firebug-lite/1.4.0/','#startOpened');">Debug Console</a> */}
    </section>
  );
};

export default LoginSuccess;
