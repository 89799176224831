import { useContext, useEffect, useState } from 'react';
import { Alert, AlertTitle, Box, Link, Stack, Typography } from '@mui/material';
import { constants } from '../../utils/constants';
import { LoadingContext } from '../../context/loadingContext';
import { PageContext } from '../../context/context';
import { searchSOQL } from '../../utils/queries';
import ActionButtons from '../ActionButtons/ActionButtons';
import axios from 'axios';
import renderComponents from '../../utils/renders/renderNestedComponents';
import ColoredSvg from '../CustomSvg/ColoredSvg';

import styles from './CreateRecord.module.css';


/**
 * @summary View for creating / editing records
 * @module components/RelatedLists/CreateRecord
 * @subcategory RelatedLists
 * @description UI for creating and editing new contacts, companies and opportunities.
 * This component will be the only frame displayed within the intor screen template when activated
 * @param {object} props Component properties passed from parent
 * @requires Context/PageContext
 * @requires Context/LoadingContext
 */
const CreateRecord = (props) => {
  // console.log('from createRecord', props);
  const { duplicateErrorUrl, iconName, inputFields, isCompact, objectName, onCancel, onSuccessSave, recordId, title, variant } = props || {};
  const { caseId, interactionId, salesforceUser, themeColors } = useContext(PageContext);
  const { setIsLoading } = useContext(LoadingContext);

  const [saveResultToast, setSaveResultToast] = useState(null);
  const [requiredFields, setRequierdFields] = useState([]);
  const [record, setRecord] = useState(() => {
    const newRecord = {};
    inputFields?.forEach(item => {
      if (item.sourceField === "{!activityId}") {
        newRecord[item.targetField] = interactionId;
      }
      if (item.sourceField === "{!caseId}") {
        newRecord[item.targetField] = caseId;
      }
      if (item.defaultValue) {
        newRecord[item.targetField] = item.defaultValue;
      }
    });
    return newRecord;
  });

  useEffect(() => {
    let required = [];
    inputFields?.forEach(field => {
      if (field?.attributes?.required) {
        required.push(field)
      };
    })
    setRequierdFields(required);
  }, []);


  const createRecord = async (e) => {
    let isValid = true;
    const missingFields = [];
    if (requiredFields.length > 0) {
      requiredFields.forEach(field => {
        if (!record[field.targetField]) {
          missingFields.push(field.label);
          isValid = false;
        };
      });
    };

    if (isValid) {
      setIsLoading(true);
      let url = `${constants.REACT_SERVER_URL}/api/v2/query/create/${salesforceUser?.account}/${salesforceUser?.instance}/${salesforceUser?.accessToken}/${salesforceUser?.refreshToken}?o=${objectName}`;
      const options = { headers: { 'Content-Type': 'application/json' } };

      let response;
      try {
        //console.log('recordId, objectName, record', recordId, objectName, record)
        if (!!recordId) {
          url = `${constants.REACT_SERVER_URL}/api/v2/query/update/${salesforceUser?.account}/${salesforceUser?.instance}/${salesforceUser?.accessToken}/${salesforceUser?.refreshToken}/${recordId}?o=${objectName}`;
          response = await axios.patch(url, JSON.stringify(record), options);
        } else {
          response = await axios.post(url, JSON.stringify(record), options);
        }
      } catch (e) {
        console.warn('create record error', e);
        handleOnSaveError(e.response?.data);
      };

      if (response?.data?.id) {
        // post success => data = {errors:[],id:'',success:true}
        // patch success => data = ''
        // let parent handle success data
        onSuccessSave({
          recordId: response.data.id,
          objectName: objectName
        });
      } else {
        // ERROR HANDLING
      }
      setIsLoading(false);
    } else if (missingFields.length > 0) {
      setSaveResultToast(
        <Alert severity="error" className="m-bottom_large">
          <AlertTitle>Missing required fields</AlertTitle>
          <ul style={{ paddingInlineStart: "20px", margin: 0 }}>
            {missingFields.map((fieldLabel, i) =>
              <li key={`required-${i}`}>{fieldLabel}</li>
            )}
          </ul>
        </Alert>
      );
    }
  };

  const handleInputOnChange = (detail) => {
    // Special handling for Currency Code Fields.
    // Potentially extend this to handle other field dependencies like picklist

    /*if (detail.targetField === "AccountId") {
      setAccountDetails(detail.value)
    };*/
    inputFields.forEach(inputConfig => {
      if (detail.targetField === 'CurrencyIsoCode') {
        if (inputConfig.type === 'currency') {
          inputConfig.currencyCode = detail.value;
        }
      }
      if (inputConfig.type === 'picklist') {
        if (detail.targetField === 'RecordTypeId') {
          inputConfig.recordTypeId = detail.value;
        }
        if (detail.targetField === inputConfig.controlField) {
          if (detail.multiple) {
            if (detail.value?.length > 0) {
              inputConfig.controlValues = detail.value.map(o => { return o.value });
            } else {
              inputConfig.controlValues = null;
            }
          } else {
            inputConfig.controlValues = [detail.value];
          }
        }
      }
    });

    //console.log("new val", detail);
    setRecord(prev => {
      if (detail.type === 'lookup') {
        prev[detail.targetField] = detail.value?.value;
      } else if (detail.type === 'email') {
        prev[detail.targetField] = detail.value?.join("; ");
      } else if (detail.type === 'picklist' && detail.multiple) {
        const newValues = [];
        detail.value?.forEach(o => {
          if (o.value) newValues.push(o.value);
        });
        prev[detail.targetField] = newValues.length > 0 ? newValues.join(";") : null;
      } else if (detail.type === 'checkbox') {
        prev[detail.targetField] = !!detail.value;
      } else {
        prev[detail.targetField] = detail.value;
      }
      //console.log({ ...prev });
      return { ...prev };
    });
  };

  const handleOnSaveError = async (data) => {
    if (data) {
      if (data.name === "DUPLICATES_DETECTED" || data.errorCode === "DUPLICATES_DETECTED") {
        await buildDuplicateRecordsMessage(data);
      } else if (data.name === "INVALID_FIELD" || data.errorCode === "INVALID_FIELD") {
        setSaveResultToast(
          <Alert severity="error" className="m-bottom_large">
            <AlertTitle>Invalid Field Detected</AlertTitle>
            <Box>
              <Typography variant="body2">Unable to save record. Please reach out to an admin to confirm the following:</Typography>
              <ul style={{ paddingInlineStart: "20px", margin: 0 }}>
                <li>All API names are spelled correctly.</li>
                <li>All fields used in the layout exist in the org.</li>
                <li>Current user has Edit access to all fields in this layout.</li>
              </ul>
            </Box>
          </Alert>
        );
      } else {
        const errorList = [];
        let errorTitle = "Errors!";
        if (Array.isArray(data)) {
          data.forEach(error => {
            errorList.push({
              fieldName: error.fields + ": ",
              message: error.message
            });
          });
        } else if (Array.isArray(data.fields)) {
          errorList.push({
            fieldName: data.errorCode,
            messages: data.fields
          });
        } else {
          errorList.push({
            message: data.errorCode
          });
        }
        // console.log('errorlist', errorList)
        setSaveResultToast(
          <Alert severity="error" className="m-bottom_large">
            <AlertTitle>{errorTitle}</AlertTitle>
            <ul style={{ paddingInlineStart: "16px", margin: 0 }}>
              {errorList.map((error, index) => (
                <li key={`error-${index}`}>
                  {error.fieldName && `${error.fieldName} `}
                  {(error.messages && error.messages.length > 0) ?
                    <ul style={{ paddingInlineStart: "8px", margin: 0 }}>
                      {error.messages.map((message, index) => (
                        <li key={`error-message-${index}`}>
                          {message}
                        </li>
                      ))}
                    </ul>
                    :
                    error.message
                  }
                </li>
              ))}
            </ul>
          </Alert>
        );
      }
    }
  };

  const buildDuplicateRecordsMessage = async (data) => {
    if (typeof duplicateErrorUrl === "string") {
      setSaveResultToast(
        <Alert severity="warning" className="m-bottom_large">
          <AlertTitle>Similar Records Exist</AlertTitle>
          <Box>
            <Typography variant="body2">A similar record found for: <b>{title}</b></Typography>
            <Typography variant="body2" sx={{ paddingTop: "0.5rem" }}>Please create/resolve in <Link href={duplicateErrorUrl} title="Link to Salesforce List View" target="_blank" underline="hover">Salesforce</Link> directly</Typography>
          </Box>
        </Alert>
      );
    } else {
      const errorObj = {
        message: "",
        duplicateLinks: []
      };
      const duplicateIds = {};
      if (data.duplicateResult) {
        errorObj.message = data.duplicateResult.errorMessage;

        // Get duplicate record Ids
        data.duplicateResult.matchResults.forEach(matchResult => {
          if (!duplicateIds[matchResult.entityType]) {
            duplicateIds[matchResult.entityType] = [];
          }
          matchResult.matchRecords?.forEach(matchRecord => {
            duplicateIds[matchResult.entityType].push(matchRecord.record.Id);
          });
        });

        Object.keys(duplicateIds).forEach(async objectName => {
          const options = { headers: { 'Content-Type': 'application/json' } };
          const params = {
            userInfo: salesforceUser,
            objectName: objectName,
            labelField: objectName === "Case" ? "CaseNumber" : "Name",
            filter: `Id IN ('${duplicateIds[objectName].join("','")}')`
          };

          // Query for duplicate record information
          let records = [];
          try {
            records = await searchSOQL(params, options, [params.labelField, "Id"]);
          } catch (e) {
            console.warn("Fetch duplicate records", params, e);
          };
          records.forEach(dupeRecord => {
            errorObj.duplicateLinks.push(
              <Link href={`${salesforceUser.url}/${dupeRecord.Id}`} target="_blank" underline="hover" title={dupeRecord[params.labelField]}
                sx={{ display: "inline-list-item", width: "100%", overflow: "hidden", whiteSpace: "nowrap" }}>
                {dupeRecord[params.labelField]}
              </Link>
            );
          });

          setSaveResultToast(
            <Alert severity="warning" className="m-bottom_large">
              <AlertTitle>Similar Records Exist</AlertTitle>
              <Box>
                <Typography variant="body2">The record you're about to create looks like a duplicate. Please create/resolve in Salesforce directly.</Typography>
                {!!errorObj.message && <Typography variant="body2" sx={{ paddingTop: "0.5rem" }}>{errorObj.message}</Typography>}
                <ul style={{ marginBlockStart: "0.5rem", marginBlockEnd: "0.5rem", paddingInlineStart: "1.25rem" }}>
                  {errorObj.duplicateLinks.map((dupeLink, index) => (
                    <li key={`error-message-${index}`} style={{}}>
                      {dupeLink}
                    </li>
                  ))}
                </ul>
              </Box>
            </Alert>
          );
        });
      }
    }
  }

  return (
    <div style={{ position: "relative" }} data-is-compact={`${!!isCompact}`} data-variant={variant}>
      <div className={!!recordId && constants.EMAIL_CLIENT === "outlook" ? styles.marginTopForOutlook : styles.container}>
        {!isCompact &&
          <div className={styles.header} style={{ backgroundColor: variant === 'brand' ? themeColors.primary : null }}>
            {iconName &&
              <div style={{ marginRight: "0.5rem" }}>
                <ColoredSvg iconName={iconName} color={variant === "brand" ? '#fff' : themeColors.tertiary} />
              </div>
            }
            <div className={styles.headerLabel}><b>{title}</b></div>
          </div>
        }
        <div className={styles.bodyContainer}>
          {saveResultToast}
          <Stack spacing={1} sx={{ paddingBottom: ".5rem" }}>
            {inputFields && inputFields.length > 0 &&
              inputFields.map((item, index) => (
                item.type !== "hidden" && renderComponents(item, index, null, handleInputOnChange)
              ))
            }
          </Stack>
        </div>
      </div>
      <ActionButtons isCompact={isCompact}
        saveProps={{ color: themeColors.secondary }}
        onSave={createRecord}
        onCancel={onCancel} />
    </div>
  );
};

export default CreateRecord;
